import { IBuilderView, IItinerary, IItineraryPage } from '../../../helpers/Interfaces';
import { useEffect, useRef, useState } from 'react';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import Dashboard from '../../../components/Dashboard/Dashboard'
import SecondaryButton from '../../../components/SecondaryButton/SecondaryButton';
import CancelIcon from '../../../assets/images/builder/Cancel';
import SendIcon from '../../../assets/images/builder/SendIcon';
import TransparentButton from '../../../components/TransparentButton/TransparentButton';
import InfoSvg from '../../../assets/images/builder/infoSvg.svg';
import SummeryIcon from '../../../assets/images/builder/SummeryIcon';
import BuilderIcon from '../../../assets/images/builder/BuilderIcon';
import TabDivider from '../../../assets/images/builder/TabDivider';
import Page from './controls/Page';
import Layout from './controls/Layouts';
import Controls from './controls/Controls';
import MenuControls from './controls/MenuControls';
import BuilderFunctions from './BuilderFunctions';
import MonitorGif from '../../../assets/images/builder/monitor.gif'
import InitializeBuilder from '../../../components/Builder/InitializeBuilder/InitializeBuilder';
import FullPageIcon from '../../../assets/images/builder/PageSettings/FullPageIcon';
import SplitPageIcon from '../../../assets/images/builder/PageSettings/SplitPageIcon';
import OverlayPageIcon from '../../../assets/images/builder/PageSettings/OverlayPageIcon';
import SaveDraftIcon from '../../../assets/images/builder/saveDraftIcon.svg';
import './Builder.scss'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { formatDate, generateUniqueKey } from '../../../helpers/helperfunctions';
import SaveEditorState from './plugins/SaveEditorState';
import { LexicalEditor } from 'lexical';
import MagicWand from '../../../components/Builder/MagicWand/MagicWand';
import PdfPreview from '../../../components/Builder/PdfPreview/PdfPreview';
import { useNavigate } from 'react-router-dom';
import { setItineraryId } from '../../../store/slices/itinerarySlice';
import { TableContext } from './LexicalCustom/TablePlugins/TablePlugin';
import TableCellResizerPlugin from './LexicalCustom/TablePlugins/TableResizer';
import ImagesPlugin from './LexicalCustom/ImagePlugin/ImagePlugin';
import TableActionMenuPlugin from './LexicalCustom/TablePlugins/TableActionMenuPlugin';
import { TablePlugin } from "@lexical/react/LexicalTablePlugin";
import Library from './controls/Library';
import CreateItineraryPage from '../../../components/Builder/CreateItineraryPage/CreateItineraryPage';

const Builder = () => {
    const builderFunctions = new BuilderFunctions()

    const itineraryDefaultDocument: IBuilderView = {
        name: '',
        view: { blank: false, split: false, overlay: false },
        editor: {
            leftPadding: '40', rightPadding: '40', bottomPadding: '40', topPadding: '40', backgroundColor: '', overlayColor: 'white',
            positionLeft: '20', positionTop: '20', radiusBLeft: 10, radiusTLeft: 10, radiusBRight: 10, radiusTRight: 10
        },
        canvas: { split: false },
        builder: '{"root":{"children":[{"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":" ","type":"text","version":1}],"direction":null,"format":"","indent":0,"type":"paragraph","version":1,"textFormat":0,"textStyle":""}],"direction":null,"format":"","indent":0,"type":"root","version":1}}',
        image: {
            imageUrl: '', backgroundColor: '', imageOpacity: 1, topSplitPadding: '40', bottomSplitPadding: '40', rightSplitPadding: '40', leftSplitPadding: '40',
            subTitle: { text: 'Subtitle', fontSize: '16px', fontFamily: '', bold: false, strickThrough: false, underLine: false, italic: false, color: 'black', align: 'left' },
            title: { text: 'Title', fontSize: '16px', fontFamily: '', bold: false, strickThrough: false, underLine: false, italic: false, color: 'black', align: 'left' }
        }
    };

    const divRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const itineraryId = useSelector((state: RootState) => state.itineraryId);
    // const itineraryId = '18yRJInwFw8kiREBBDSr';
    // const itineraryId = 'DMpAhJOrZ4KCDUJJzqLU';
    const [value, setValue] = useState(0);
    const [openInitializer, setOpenInitializer] = useState<boolean>(false);
    const [openPdfPreviewer, setOpenPdfPreviewer] = useState<boolean>(false);
    const [openCreateItineraryPage, setOpenCreateItineraryPage] = useState<boolean>(false);
    const [accordion, setAccordion] = useState<{ document: boolean, page: boolean, overlayContent: boolean, pageTitleSubTitle: boolean, backgroundImage: boolean, imageView: boolean }>(
        { document: false, page: false, overlayContent: false, pageTitleSubTitle: false, backgroundImage: false, imageView: false });
    const [lastUpdated, setLastUpdated] = useState<string>();
    const [editorStateJSON, setEditorStateJSON] = useState<string | null>(null);
    const [editor, setEditor] = useState<LexicalEditor | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingSave, setLoadingSave] = useState<boolean>(false);
    const [unableEditor, setUnableEditor] = useState<boolean>(false);
    const [itinerary, setItinerary] = useState<IItinerary>({});
    const [layout, setLayout] = useState<IBuilderView>(itineraryDefaultDocument);
    const [selectedPageIndex, setSelectedPageIndex] = useState<number | undefined>();
    const [pdfLink, setPdfLink] = useState<string | null>();
    const [floatingAnchorElem, setFloatingAnchorElem] = useState(null);
    const [scale, setScale] = useState<number>(1);
    let itineraryName = '';

    useEffect(() => {
        if (itineraryId) {
            initializeBuilder();
        }
    }, [])

    useEffect(() => {
        if (pdfLink) {
            setOpenPdfPreviewer(true);
        }
    }, [pdfLink])

    useEffect(() => {
        updateItinerary();
    }, [itinerary])

    const updateItinerary = async () => {
        const newItinerary = itinerary;
        if (newItinerary.pages) {
            let index: number = newItinerary.pages?.findIndex(p => p.id === layout.id);
            if (index !== -1) {
                newItinerary.pages?.splice(index, 1);
                newItinerary.pages.splice(index, 0, layout);
            }

            await builderFunctions.updateItinerary(itineraryId, newItinerary).then(() => {
                setLastUpdated(new Date().toISOString())
            });
        }
    }

    const initializeBuilder = () => {
        setLoading(true)
        builderFunctions.getItineraryData(itineraryId).then((response) => {
            const itineraryData = response as IItinerary;
            if (itineraryData) {
                setUnableEditor(true);
                if (!itineraryData?.pages) {
                    itineraryData.pages = [itineraryDefaultDocument];
                }
                setItinerary(itineraryData);

                if (itineraryData.resolution === '') {
                    setOpenInitializer(true);
                }
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        if (editorStateJSON && editor) {
            const parsedState = editor.parseEditorState(editorStateJSON);
            editor.update(() => {
                editor.setEditorState(parsedState);
            });
        }
    }, [editor, editorStateJSON]);

    const handleSelectPage = (page: number) => {
        const newItinerary = itinerary;
        if (layout?.id && newItinerary.pages) {
            let index: number = newItinerary.pages?.findIndex(p => p.id === layout.id);
            if (index !== -1) {
                newItinerary.pages?.splice(index, 1);
                newItinerary.pages.splice(index, 0, layout);
            }
        }

        setSelectedPageIndex(page);
        if (newItinerary?.pages) {
            const selectedPage = newItinerary.pages.find(p => p.id === page);
            setEditorStateJSON(selectedPage?.builder ?? '');
            if (selectedPage) {
                setLayout({ ...selectedPage });
            }
        }
        setItinerary({ ...newItinerary });
    }

    const handleAddPage = (page: IBuilderView) => {
        if (selectedPageIndex && page) {
            const newItineraryPages = itinerary?.pages?.map((_page) => (_page.id === selectedPageIndex) ? { ...page, id: _page.id } : _page);
            setItinerary({ ...itinerary, pages: newItineraryPages })
            setEditorStateJSON(page.builder ?? '');
            setLayout({ ...page, id: layout.id });
        }
    }

    const handleAddNewPage = () => {
        const newItinerary = itinerary;
        let newPage = itineraryDefaultDocument;
        newPage.name = 'New Page'
        newPage.id = generateUniqueKey()
        newItinerary.pages?.push(newPage);
        setItinerary({ ...newItinerary });
    }

    const handleRemovePage = (layoutId: number) => {
        const newItinerary = itinerary;
        if (layoutId && newItinerary.pages) {
            let index: number = newItinerary.pages?.findIndex(p => p.id === layoutId);
            if (index !== -1) {
                newItinerary.pages?.splice(index, 1);
            }
        }
        setItinerary({ ...newItinerary });
    }

    const handleRenamePage = (pageId: number, newName: string) => {
        if (pageId === selectedPageIndex) {
            setLayout({ ...layout, name: newName });
        }

        const newItinerary = itinerary;
        const updatedPages = newItinerary.pages?.map(page => page.id === pageId ? { ...page, name: newName } : page);
        setItinerary({ ...itinerary, pages: updatedPages });
    }

    const cancelPublish = () => {
        setOpenPdfPreviewer(false);
        setPdfLink(null);
    }

    const publishItinerary = async () => {
        setItinerary({ ...itinerary, pdfUrl: pdfLink ?? '', type: 'sent' })

        setInterval(exit, 300)
    }

    const exit = () => {
        dispatch(setItineraryId(''));
        navigate("/dashboard/lead-tracker");
    }

    const onRef = (_floatingAnchorElem: any) => {
        if (_floatingAnchorElem !== null) {
            setFloatingAnchorElem(_floatingAnchorElem);
        }
    };

    return (
        <Dashboard headerTitle='Itinerary Builder'>
            <TableContext>
                <LexicalComposer initialConfig={{
                    ...builderFunctions.editorConfig,
                    editorState: (editor) => {
                        setEditor(editor);
                    }
                }}>
                    {/* <MaxLinesPlugin maxLines={21} /> */}
                    <TablePlugin />
                    <TableCellResizerPlugin />
                    <HistoryPlugin />
                    <ImagesPlugin captionsEnabled={false} />
                    <SaveEditorState onUpdate={(val) => { setLayout({ ...layout, builder: val.json, html: val.html }) }} />
                    <div style={{ opacity: unableEditor ? 1 : 0.5, pointerEvents: unableEditor ? 'initial' : 'none' }} className="builder-container-outer">
                        <div className='builder-container'>
                            <div className='builder-outer'>
                                <div className="builder-title fz-24">
                                    <div role='none' onClick={() => (itineraryName = itinerary.name ?? '')}
                                        onBlur={() => setItinerary({ ...itinerary, name: itineraryName })}
                                        onInput={(val: any) => (itineraryName = val.target.innerText)}
                                        className='editor-area' contentEditable>
                                        {itinerary?.name ?? ' New Itinerary'}
                                    </div>
                                </div>
                                <div className="builder-outer-controls">
                                    <button onClick={exit} className="icon-builder-btn">
                                        <CancelIcon />
                                    </button>
                                    <SecondaryButton loading={loadingSave} label="Preview & Publish" icon={<SendIcon />} onClick={() => builderFunctions.generatePdf(setLoadingSave, itinerary.resolution ?? '420x594', itinerary.id ?? '', setPdfLink, itinerary.pages)} />
                                </div>
                            </div>
                            <div className='builder-inner'>
                                <div className="builder-editor-container">
                                    <MenuControls scale={scale} setScale={setScale} selectedPageIndex={selectedPageIndex} />
                                    {(!layout?.view?.blank && !layout?.view?.overlay && !layout?.view?.split)
                                        ? (
                                            <div className="editor-inner-container layout-select-outer">
                                                <img className='monitor-image' src={MonitorGif} alt="Monitor Svg" />
                                                <span className="header-title">Start by Selecting a {(selectedPageIndex || (selectedPageIndex == 0)) ? 'Layout' : 'Page'}</span>
                                                <span className="header-description">Let’s get into it and create an amazing itinerary for your clients. You can create a layout from scratch or import a saved template.</span>
                                                {(selectedPageIndex || (selectedPageIndex == 0))
                                                    ? (<div className="control-row">
                                                        <FullPageIcon removeBorder selected={layout?.view?.blank} onClick={() => setLayout({ ...layout, view: { blank: true, split: false, overlay: false } })} />
                                                        <SplitPageIcon removeBorder selected={layout?.view?.split} onClick={() => setLayout({ ...layout, view: { blank: false, split: true, overlay: false } })} />
                                                        <OverlayPageIcon removeBorder selected={layout?.view?.overlay} onClick={() => setLayout({ ...layout, view: { blank: false, split: false, overlay: true } })} />
                                                    </div>)
                                                    : (<></>)}
                                            </div>
                                        )
                                        : (<div className='editor-outer-container'>
                                            <div ref={divRef} style={builderFunctions.editorContainer(layout, itinerary.resolution ?? '420x594', scale)} className='editor-inner-container'>
                                                <div style={builderFunctions.editorRightContainer(layout)} className="editor-image-outer">
                                                    {layout.image?.imageUrl ? (<img className='img' style={builderFunctions.editorRightImage(layout, itinerary.resolution ?? '420x594')} src={layout.image?.imageUrl} alt="" />) : (<></>)}
                                                    <div className='editor-image-content img' style={builderFunctions.imageEditorStyles(layout, builderFunctions)}>
                                                        <div style={builderFunctions.imageTitleStyle(layout)} className="header-title">{layout.image?.title?.text}</div>
                                                        <div style={builderFunctions.imageSubTitleStyle(layout)} className="sub-header-title">{layout.image?.subTitle?.text}</div>
                                                    </div>
                                                </div>
                                                <div role='none' onBlur={updateItinerary} style={builderFunctions.editorStyles(layout)} className="editor-inner">
                                                    {(layout.image?.imageUrl && !layout.view.split) ? (<img className='editor-img' style={{ borderRadius: builderFunctions.getImageBorder(layout), opacity: layout?.image?.imageOpacity, position: layout.view.overlay ? 'static' : 'revert-layer' }} src={layout.image?.imageUrl} alt="" />) : (<></>)}
                                                    {!layout.view.overlay
                                                        ? (<RichTextPlugin
                                                            contentEditable={<div className="editor" ref={onRef}><ContentEditable className="editor-input" /></div>}
                                                            ErrorBoundary={LexicalErrorBoundary}
                                                        />)
                                                        : (<div style={builderFunctions.editorOverlayStyles(layout)} className='editor-overlay-outer'>
                                                            <RichTextPlugin
                                                                contentEditable={<div className="editor" ref={onRef}><ContentEditable className="editor-overlay-input" /></div>}
                                                                ErrorBoundary={LexicalErrorBoundary}
                                                            />
                                                        </div>)}
                                                </div>
                                            </div>
                                        </div>)}
                                    <div className='latest-updated primary-font'>
                                        <button onClick={updateItinerary}><img src={SaveDraftIcon} alt="SaveDraftIcon" /></button>
                                        <span>{lastUpdated ? `changes saved at ${formatDate(lastUpdated, 'H:mm:ss')}` : itinerary.modifiedDate ? `changes saved at ${formatDate(itinerary.modifiedDate, 'H:mm:ss')}` : 'changes not saved'}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="builder-controls">
                            <div className='upper-bts'>
                                <div className="upper-bts-content">
                                    <TransparentButton icon={<SummeryIcon />} label="summery" onClick={() => { }} />
                                    <TransparentButton active icon={<BuilderIcon />} label="builder" onClick={() => { }} />
                                </div>
                                <button className="upper-bts-content">
                                    <img src={InfoSvg} alt="Info Svg" />
                                </button    >
                            </div>
                            <div role='none' onBlur={() => updateItinerary()} className="controls-outer">
                                <div className='controls-header'>
                                    <button className={`fz-16 tab ${value === 0 ? 'tab-active' : ''}`} onClick={builderFunctions.handleChange(0, setValue)}>pages</button>
                                    <TabDivider />
                                    <button className={`fz-16 tab ${value === 1 ? 'tab-active' : ''}`} onClick={builderFunctions.handleChange(1, setValue)}>Layout</button>
                                    <TabDivider />
                                    <button className={`fz-16 tab ${value === 2 ? 'tab-active' : ''}`} onClick={builderFunctions.handleChange(2, setValue)}>Controls</button>
                                    <TabDivider />
                                    <div className={`fz-16 tab ${value === 3 ? 'tab-active' : ''}`} onClick={builderFunctions.handleChange(3, setValue)}>library</div>
                                </div>
                                <div className='table-divider' />
                                {value == 0 ? (<Page onRenamePage={handleRenamePage} onDeletePage={handleRemovePage} handleAddNewPage={handleAddNewPage} selectedIndex={selectedPageIndex} setSelectedPage={handleSelectPage}
                                    itinerary={itinerary} createPage={() => setOpenCreateItineraryPage(true)} />) : (<></>)}
                                {value == 1 ? (<Layout itinerary={itinerary} itineraryId={itineraryId} setLayout={setLayout} layout={layout} accordion={accordion} setAccordion={setAccordion} />) : (<></>)}
                                {value == 2 ? (<Controls />) : (<></>)}
                                {value == 3 ? (<Library addPage={handleAddPage} />) : (<></>)}
                            </div>
                        </div>
                        <CreateItineraryPage selectedPageId={layout.id ?? ''} itinerary={itinerary} open={openCreateItineraryPage} close={() => setOpenCreateItineraryPage(false)} />
                        <InitializeBuilder setItinerary={setItinerary} itinerary={itinerary} open={openInitializer} close={() => setOpenInitializer(false)} />
                        <PdfPreview itineraryName={itinerary.name ?? 'explor'} publish={publishItinerary} pdfLink={pdfLink ?? ''} open={openPdfPreviewer} close={cancelPublish} />
                        <MagicWand open={loading} />
                    </div>
                    {floatingAnchorElem && <TableActionMenuPlugin anchorElem={floatingAnchorElem} />}
                </LexicalComposer>
            </TableContext>
        </Dashboard>
    );
}

export default Builder
