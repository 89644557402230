import React, { useEffect, useState } from 'react'
import './Library.scss'
import BuilderFunctions from '../BuilderFunctions'
import { IItineraryPage } from '../../../../helpers/Interfaces'
import { Accordion, AccordionDetails, AccordionSummary, Box, Skeleton } from '@mui/material'
import AccordionDownIcon from '../../../../assets/images/AccordionDownIcon'
import PageSettingsIcon from '../../../../assets/images/builder/PageSettingsIcon'
import AssetsTypes from '../../../../assets/images/builder/AssetsTypes'
import AssetsPage from '../../../../assets/images/builder/AssetsPage'
import AssetsTitle from '../../../../assets/images/builder/AssetsTitle'
import AssetsCategory from '../../../../assets/images/builder/AssetsCategery'
import AssetsDate from '../../../../assets/images/builder/AssetsDate'
import PluseSvg from '../../../../assets/images/buttons/PluseSvg'
import { formatDate } from '../../../../helpers/helperfunctions'

type LibraryProps = {
  addPage: CallableFunction
}

const Library = ({ addPage }: LibraryProps) => {
  const [pages, setPages] = useState<IItineraryPage[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const builderFunctions = new BuilderFunctions();

  useEffect(() => {
    builderFunctions.getItineraryPages(setPages, setLoading)
  }, [])

  return (
    <div className='library-container'>
      <Accordion expanded classes={{ root: 'accordion-container' }}>
        <AccordionSummary
          expandIcon={<AccordionDownIcon />}
          aria-controls="panel1-content"
          id="panel1-header">
          <AssetsTypes />
          All Asset Types
        </AccordionSummary>
        <AccordionDetails>
          <div className="asset-section-outer">
            <div className="asset-header">Pages
              <div className="asset-division-line" />
            </div>
            <div className="asset-content-outer">
              {loading
                ? (<Box minWidth={'260px'} >
                  <Skeleton variant="rounded" height={'86px'} sx={{ marginBottom: 'clamp(0.625rem, 0.625vw, 0.75rem)' }} />
                  <Skeleton variant="rounded" height={'86px'} sx={{ marginBottom: 'clamp(0.625rem, 0.625vw, 0.75rem)' }} />
                </Box>)
                : (pages.map((pageData) => {
                  return (
                    <div className="asset-content">
                      <div className="asset-content-inner-header">
                        <div className='title'>
                          <AssetsPage />
                          {pageData.name}
                        </div>
                        <button onClick={() => addPage(pageData.page)} className="insert-button">
                          <PluseSvg />
                        </button>
                      </div>
                      <div className="asset-division-line" />
                      <div className="asset-content-inner-body">
                        <div className="asset-content-data">
                          <AssetsTitle />
                          Page
                        </div>
                        <div className="asset-content-data">
                          <AssetsCategory />
                          {pageData.category}
                        </div>
                        <div className="asset-content-data">
                          <AssetsDate />
                          {formatDate(pageData.createdDate, 'MMM. DD, YYYY')}
                        </div>
                        {/* <div className="asset-content-data">
                    <AssetsTitle />
                    about us, general pages, english
                  </div> */}
                      </div>
                    </div>
                  )
                }))}
            </div>
          </div>
        </AccordionDetails>
      </Accordion >
    </div>
  )
}

export default Library