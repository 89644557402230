import { $applyNodeReplacement, createEditor, DecoratorNode, Klass, LexicalEditor, EditorThemeClasses } from 'lexical';
import React, { Suspense } from 'react';

// Lazy load the ImageComponent
const ImageComponent = React.lazy(() => import('./ImageComponent'));

// Convert the DOM element (HTMLImageElement) to a Lexical node
function convertImageElement(domNode: Node): { node: ImageNode } | null {
  if (domNode instanceof HTMLImageElement) {
    const { alt: altText, src, width, height } = domNode;
    const node = $createImageNode({ altText, height: Number(height), src, width: Number(width) } as any);
    return { node };
  }
  return null;
}

// ImageNode class, which extends Lexical's DecoratorNode
export class ImageNode extends DecoratorNode<JSX.Element> {
  private __src: string;
  private __altText: string;
  private __width: number | 'inherit';
  private __height: number | 'inherit';
  private __maxWidth: number;
  private __showCaption: boolean;
  private __caption: LexicalEditor;
  private __captionsEnabled: boolean;

  // Return the type of node
  static getType(): string {
    return 'image';
  }

  // Clone the node
  static clone(node: ImageNode): ImageNode {
    return new ImageNode(
      node.__src,
      node.__altText,
      node.__maxWidth,
      node.__width,
      node.__height,
      node.__showCaption,
      node.__caption,
      node.__captionsEnabled,
      node.__key
    );
  }

  // Import JSON to create a node
  static importJSON(serializedNode: any): ImageNode {
    const { altText, height, width, maxWidth, caption, src, showCaption } = serializedNode;
    const node = $createImageNode({
      altText,
      height,
      maxWidth,
      showCaption,
      src,
      width,
    } as any);
    const nestedEditor = node.__caption;
    const editorState = nestedEditor.parseEditorState(caption.editorState);
    if (!editorState.isEmpty()) {
      nestedEditor.setEditorState(editorState);
    }
    return node;
  }

  // Export DOM to create an HTML image element
  exportDOM(): { element: HTMLElement } {
    const element = document.createElement('img');
    element.setAttribute('src', this.__src);
    element.setAttribute('alt', this.__altText);
    element.setAttribute('width', this.__width.toString());
    element.setAttribute('height', this.__height.toString());
    return { element };
  }

  // Import DOM to convert an HTML element to a Lexical node
  static importDOM(): any {
    return {
      img: () => ({
        conversion: convertImageElement,
        priority: 0,
      }),
    };
  }

  // Constructor for the ImageNode
  constructor(
    src: string,
    altText: string,
    maxWidth: number,
    width: number | 'inherit',
    height: number | 'inherit',
    showCaption: boolean,
    caption: LexicalEditor,
    captionsEnabled: boolean,
    key?: string
  ) {
    super(key);
    this.__src = src;
    this.__altText = altText;
    this.__maxWidth = maxWidth;
    this.__width = width || 'inherit';
    this.__height = height || 'inherit';
    this.__showCaption = showCaption || false;
    this.__caption = caption || createEditor();
    this.__captionsEnabled = captionsEnabled || false;
  }

  // Export the node to JSON
  exportJSON(): any {
    return {
      altText: this.getAltText(),
      caption: this.__caption.toJSON(),
      height: this.__height === 'inherit' ? 0 : this.__height,
      maxWidth: this.__maxWidth,
      showCaption: this.__showCaption,
      src: this.getSrc(),
      type: 'image',
      version: 1,
      width: this.__width === 'inherit' ? 0 : this.__width,
    };
  }

  // Set width and height of the node
  setWidthAndHeight(width: number, height: number): void {
    const writable = this.getWritable();
    writable.__width = width;
    writable.__height = height;
  }

  // Set whether to show caption
  setShowCaption(showCaption: boolean): void {
    const writable = this.getWritable();
    writable.__showCaption = showCaption;
  }

  // DOM-related functions
  createDOM(config: { theme: EditorThemeClasses }): HTMLElement {
    const span = document.createElement('span');
    const className = config.theme.image;
    if (className !== undefined) {
      span.className = className;
    }
    return span;
  }

  updateDOM(): boolean {
    return false;
  }

  // Get source and alt text
  getSrc(): string {
    return this.__src;
  }

  getAltText(): string {
    return this.__altText;
  }

  // Decorate the node with the ImageComponent
  decorate(): JSX.Element {
    return (
      <Suspense fallback={null}>
        <ImageComponent
          src={this.__src}
          altText={this.__altText}
          width={this.__width}
          height={this.__height}
          maxWidth={this.__maxWidth}
          nodeKey={this.getKey()}
          showCaption={this.__showCaption}
          caption={this.__caption}
          captionsEnabled={this.__captionsEnabled}
          resizable={true}
        />
      </Suspense>
    );
  }
}

// Helper function to create a new ImageNode
export function $createImageNode({
  altText,
  height,
  maxWidth = 500,
  captionsEnabled,
  src,
  width,
  showCaption,
  caption,
  key,
}: {
  altText: string;
  height: number;
  maxWidth?: number;
  captionsEnabled: boolean;
  src: string;
  width: number;
  showCaption: boolean;
  caption?: LexicalEditor;
  key?: string;
}): ImageNode {
  return $applyNodeReplacement(
    new ImageNode(src, altText, maxWidth, width, height, showCaption, caption!, captionsEnabled, key)
  );
}

// Helper function to check if a node is an ImageNode
export function $isImageNode(node: unknown): node is ImageNode {
  return node instanceof ImageNode;
}