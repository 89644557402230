import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { CSSProperties, RefObject } from "react";
import BuilderTheme from "./theme/BuilderTheme";
import { ListNode, ListItemNode } from '@lexical/list'
import { IBuilderView, IItinerary } from "../../../helpers/Interfaces";
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { LinkNode } from '@lexical/link'
import FirebaseFunctions from "../../../helpers/firebaseFunctions";
import ApiService from "../../../services/ApiService";
import { pdfHtmlContent } from "../../../helpers/PdfFileContent";
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { ImageNode } from "./LexicalCustom/ImageNode";
import { getWidthHeight } from "../../../helpers/helperfunctions";
import { rejects } from "assert";

export default class BuilderFunctions {
    firebaseFunction = new FirebaseFunctions();
    apiService = new ApiService();

    editorConfig = {
        namespace: 'Explor App Builder',
        onError: (error: any) => console.error(error),
        theme: BuilderTheme,
        nodes: [ListNode, ListItemNode, HeadingNode, QuoteNode, LinkNode, ImageNode, TableCellNode, TableNode, TableRowNode],
    };

    handleChange = (newValue: number, setValue: CallableFunction) => () => {
        setValue(newValue);
    };

    getItineraryData = async (itineraryId: string) => {
        try {
            const response = await this.firebaseFunction.getItinerary(itineraryId);
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    getItineraryPages = async (setPages: CallableFunction, setLoading: CallableFunction) => {
        setLoading(true);
        this.apiService.getItineraryPages().then((response) => {
            setPages(response.data.pages)
        }).catch((error) => {
            console.error(error);
            throw error;
        }).finally(() => {
            setLoading(false)
        });
    }

    updateItinerary = async (itineraryId: string, itinerary: IItinerary) => {
        try {
            const response = await this.firebaseFunction.updateItinerary(itineraryId, itinerary);
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    submitItinerary = async (itineraryId: string, setLoading: CallableFunction) => {
        try {
            setLoading(true);
            const response = await this.apiService.submitItineraries(itineraryId);
            setLoading(false);
            return response;
        } catch (error) {
            console.error(error);
            setLoading(false);
            throw error;
        }
    }

    CustomTabPanel(props: any) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={false}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <>{children}</>}
            </div>
        );
    }

    editorStyles = (layout: IBuilderView): CSSProperties | undefined => {
        if (!layout?.view?.overlay) {
            return {
                // display: layout.view.overlay ? 'none' : 'initial',
                paddingLeft: `${layout?.editor?.leftPadding}px`,
                paddingRight: `${layout?.editor?.rightPadding}px`,
                paddingBottom: `${layout?.editor?.bottomPadding}px`,
                paddingTop: `${layout?.editor?.topPadding}px`,
                background: layout?.editor?.overlayColor,
                // backgroundImage: `url(${layout.editor.imageUrl})`,
                // backgroundSize: 'contain',
                // height: `calc((100vh - 22.6rem + calc(45px - ${layout?.editor?.bottomPadding}px) + calc(45px - ${layout?.editor?.topPadding}px)))`
            }
        } else {
            return {
                height: `calc((100vh - 22.6rem + calc(45px - ${layout?.editor?.bottomPadding}px) + calc(45px - ${layout?.editor?.topPadding}px)))`
            }
        }
    };

    editorContainer = (layout: IBuilderView, resolution: string, scale: number): CSSProperties | undefined => {
        const res = getWidthHeight(resolution);
        return {
            transform: `scale(${scale})`,
            height: res.height,
            width: res.width,
            background: layout?.view?.overlay ? layout?.editor?.overlayColor : 'white',
            flexDirection: layout?.canvas?.split ? 'row-reverse' : undefined
        }
    };

    editorRightContainer = (layout: IBuilderView): CSSProperties | undefined => {
        return {
            display: (layout?.view?.blank || layout?.view?.overlay) ? 'none' : 'initial'
        }
    };

    editorRightImage = (layout: IBuilderView, resolution: string): CSSProperties | undefined => {
        const res = getWidthHeight(resolution);

        return {
            borderRadius: this.getImageBorder(layout),
            opacity: layout?.image?.imageOpacity,
            height: '100%'
        }
    };

    editorOverlayStyles = (layout: IBuilderView,): CSSProperties | undefined => {
        return {
            paddingLeft: `${layout?.editor?.leftPadding}px`,
            paddingRight: `${layout?.editor?.rightPadding}px`,
            paddingBottom: `${layout?.editor?.bottomPadding}px`,
            paddingTop: `${layout?.editor?.topPadding}px`,
            // borderRadius: `10px`,
            borderRadius: `${layout?.editor?.radiusTLeft}px ${layout?.editor?.radiusTRight}px ${layout?.editor?.radiusBRight}px ${layout?.editor?.radiusBLeft}px`,
            backgroundColor: layout?.editor?.backgroundColor,
            top: `${layout?.editor?.positionTop}px`,
            left: `${layout?.editor?.positionLeft}px`
        }
    };

    imageTitleStyle = (layout: IBuilderView): CSSProperties | undefined => {
        return {
            fontSize: layout?.image?.title?.fontSize,
            fontFamily: layout.image?.title?.fontFamily,
            fontWeight: layout.image?.title?.bold ? 'bold' : 'normal',
            textDecoration: `${layout.image?.title?.underLine ? 'underline' : ''} ${layout.image?.title?.strickThrough ? 'line-through' : ''}`,
            fontStyle: layout.image?.title?.italic ? 'italic' : 'normal',
            color: layout.image?.title?.color,
            textAlign: layout.image?.title?.align as any
        }
    };

    imageSubTitleStyle = (layout: IBuilderView): CSSProperties | undefined => {
        return {
            fontSize: layout?.image?.subTitle?.fontSize,
            fontFamily: layout.image?.subTitle?.fontFamily,
            fontWeight: layout.image?.subTitle?.bold ? 'bold' : 'normal',
            textDecoration: `${layout.image?.subTitle?.underLine ? 'underline' : ''} ${layout.image?.subTitle?.strickThrough ? 'line-through' : ''}`,
            fontStyle: layout.image?.subTitle?.italic ? 'italic' : 'normal',
            color: layout.image?.subTitle?.color,
            textAlign: layout.image?.subTitle?.align as any
        }
    };

    imageEditorStyles = (layout: IBuilderView, builderFunctions: any): CSSProperties | undefined => {
        return {
            borderRadius: builderFunctions.getImageBorder(layout),
            backgroundColor: layout.image?.backgroundColor,
            paddingLeft: `${layout.image?.leftSplitPadding}px`,
            // paddingRight: `${layout.image?.rightSplitPadding}px`,
            // paddingBottom: `${layout.image?.bottomSplitPadding}px`,
            paddingTop: `${layout.image?.topSplitPadding}px`,
        }
    };

    getImageBorder = (layout: IBuilderView): string => {
        if (layout?.view?.overlay) {
            return '10px'
        } else if (layout?.view?.split && !layout?.canvas?.split) {
            return '10px 0px 0px 10px'
        } else if (layout?.view?.split && layout?.canvas?.split) {
            return '0px 10px 10px 0px'
        } else {
            return ''
        }
    }

    loadImages = (element: HTMLElement) => {
        const imgElements = element.getElementsByTagName("img");
        const imagePromises = Array.from(imgElements).map((img) => {
            return new Promise<void>((resolve, reject) => {
                if (img.complete) {
                    resolve();
                } else {
                    img.onload = () => resolve();
                    img.onerror = () => reject(new Error("Image failed to load"));
                }
            });
        });
        return Promise.all(imagePromises);
    };


    createPage = (props: { itineraryId: string, pageId: number | string, title?: string, type?: string, tags?: string[] }) => {
        const createPagePromise = new Promise((resolve, reject) => {
            this.apiService.createItineraryPage({ itineraryId: props.itineraryId, pageId: props.pageId, name: props.title, category: props.type, tags: props.tags })
                .then((response) => {
                    console.log(response);
                    resolve(response.data)
                })
                .catch((error) => reject(error));
        })

        return createPagePromise;
    }

    generatePdf = async (setLoading: CallableFunction, resolution: string, itineraryId: string, setPdfLink: CallableFunction, layouts?: IBuilderView[]) => {
        if (!layouts) return;
        setLoading(true);

        try {
            const res = getWidthHeight(resolution);

            const pdf = new jsPDF("l", "px", [(res.width), res.height]);
            for (const layout of layouts) {
                const tempElement = document.createElement("div");
                tempElement.innerHTML = pdfHtmlContent(layout, resolution);

                document.body.appendChild(tempElement);
                await this.loadImages(tempElement);

                const canvas = await html2canvas(tempElement, {
                    scale: 2,
                    useCORS: true,
                });

                const imgData = canvas.toDataURL("image/png");

                pdf.addImage(imgData, "jpeg", 0, 0, 0, res.height);
                if (layouts.indexOf(layout) !== (layouts.length - 1)) {
                    pdf.addPage();
                }

                document.body.removeChild(tempElement);
            }

            const link = await this.firebaseFunction.savePDFToFirebase(pdf, itineraryId);
            setPdfLink(link);
            setLoading(false);

        } catch (error) {
            setLoading(false);
            console.error("Error generating PDF:", error);
        }
    };
}

