import { useState } from 'react';
import './PageBox.scss';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DropDownMenu } from '../DropDownMenu/DropDownMenu';
import DeleteIcon from '@mui/icons-material/Delete';
import { CreateNewFolder } from '@mui/icons-material';


interface PageComponentProps {
    isChecked: boolean;
    pageName: string;
    onSelect: CallableFunction;
    onDelete: CallableFunction;
    onRename: CallableFunction;
    createPage: CallableFunction
}

const PageComponent: React.FC<PageComponentProps> = ({ pageName, isChecked, onDelete, onSelect, onRename, createPage }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [newPageName, setNewPageName] = useState('');

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const menuItems = [
        // { icon: <DriveFileRenameOutline />, action: (val: string) => {console.log(val)}, text: 'Rename' },
        { icon: <CreateNewFolder />, action: createPage, text: 'Save Page' },
        { icon: <DeleteIcon />, action: onDelete, text: 'Delete' }
    ]

    return (
        <>
            <div onClick={() => onSelect()} className="page-component">
                <div className='content-page'>
                    <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={() => onSelect()}
                    />
                    <div onBlur={() => onRename(newPageName)} onClick={() => (setNewPageName(pageName))} onInput={(val: any) => (setNewPageName(val.target.innerText))} contentEditable className='check-box'>{pageName}</div>
                </div>
                <button className="ellipsis-button" onClick={handleClick}><MoreVertIcon /></button>
            </div>
            <DropDownMenu anchorEl={anchorEl} handleClose={handleClose} open={open} menuItems={menuItems} />
        </>
    );
};

export default PageComponent;