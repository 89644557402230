import { Modal } from "@mui/material";
import "./AddTable.scss";
import { CloseSharp } from "@mui/icons-material";
import { MouseEventHandler, useState } from "react";
import MediaInput from "../MediaInput/MediaInput";
import { generateUniqueKey } from "../../../helpers/helperfunctions";
import Image from "../../../assets/images/builder/Image";
import AddImage from "../../../assets/images/builder/AddImage";
import SecondaryButton from "../../SecondaryButton/SecondaryButton";
import TextInput from "../TextInput/TextInput";

type AddTableProps = {
  open: boolean;
  close: MouseEventHandler<HTMLButtonElement> | CallableFunction | any;
  addTable: CallableFunction;
};

const AddTable = (props: AddTableProps) => {
  const [tableSize, setTableSize] = useState<{ columns?: number, rows?: number }>({});

  const handleAddTable = () => {
    props.addTable(tableSize);
    props.close()
  }

  return (
    <Modal open={props.open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <div className="add-table-container">
        <div className="header-outer">
          <span className="header-title">Insert Table</span>
          <button onClick={() => {
            setTableSize({});
            props.close();
          }}><CloseSharp /></button>
        </div>
        <div className="control-column">
          <TextInput type='number' sx={{ width: '50%' }} onChange={(val: any) => setTableSize({ ...tableSize, rows: val.target.value })} icon={<>Row</>} />
          <TextInput type='number' sx={{ width: '50%' }} onChange={(val: any) => setTableSize({ ...tableSize, columns: val.target.value })} icon={<>Col</>} />
        </div>
        <div className="footer-outer">
          <SecondaryButton onClick={handleAddTable} label='Insert Table' />
        </div>
      </div>
    </Modal>
  );
};

export default AddTable;
