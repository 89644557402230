import  { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuthLayout from '../../components/AuthLayout/AuthLayout';
import ForgotPasswordForm from './ForgotPasswordForm';
import OtpVerification from './OtpVerification';
import ForgotPasswordFunctions from './forgotPasswordFunctions';
import PasswordReset from './PasswordReset';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const forgotPasswordFunctions = new ForgotPasswordFunctions();
    const [email, setEmail] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [forgetPasswordState, setForgetPasswordState] = useState<{ state: 'verifyEmail' | 'addOtp' | 'confirmPassword' }>({ state: 'verifyEmail' });
    const [customToken, setCustomToken] = useState<string>();

    useEffect(() => {
        console.log(customToken);
    }, [customToken])

    const handleForgotPassword = (email: string) => {
        setEmail(email);
        const payload = { email };
        console.log("Payload", payload)

        forgotPasswordFunctions.sendOtp(
            payload,
            setLoading,
            (response: any) => {
                console.log(response);
                setForgetPasswordState({ state: 'addOtp' });
            },
            (error: any) => {
                console.error("Error sending reset password email ", error);
            }
        )
    };

    const handleOtpSubmit = (otp: string) => {
        setLoading(true);
        const payload = { email, otp };

        forgotPasswordFunctions.verifyOtp(
            payload,
            setLoading,
            (response: any) => {
                console.log("OTP Verified", response);
                setCustomToken(response.customToken)
                setForgetPasswordState({ state: 'confirmPassword' });
            },
            (error: any) => {
                console.error("Error verifying otp", error);
            }
        );
    };

    const handleResetPassword = async (newPassword: string): Promise<void> => {
        const payload = { customToken, password: newPassword };

        return new Promise((resolve, reject) => {
            forgotPasswordFunctions.resetPassword(
                payload,
                setLoading,
                (response: any) => {
                    console.log("Password reset successful", response);
                    resolve(response);
                    navigate('/login');
                },
                (error: any) => {
                    console.error("Error resetting password", error);
                    reject(error);
                }
            );
        });
    };

    const AuthLayoutProps = () => {
        switch (forgetPasswordState.state) {
            case "verifyEmail":
                return { title: t('forgotPasswordHeader'), description: t('forgotPasswordDescription') };
            case "addOtp":
                return { title: t('otpVerificationHeader'), description: t('otpVerificationDescription',{email}) };
            case "confirmPassword":
                return { title: t('passwordResetHeader'), description: t('passwordResetDescription') };
            default:
                return { title: '', description: '' };
        }
    };

    const getUi = () => {
        switch (forgetPasswordState.state) {
            case "verifyEmail":
                return (<ForgotPasswordForm handleForgotPassword={handleForgotPassword} loading={loading} />);
            case "addOtp":
                return (<OtpVerification email={email} handleOtpSubmit={handleOtpSubmit} loading={loading} />);
            case "confirmPassword":
                return (<PasswordReset email={email} handleResetPassword={handleResetPassword} />);
        }
    }

    return (
        <AuthLayout contentData={AuthLayoutProps()}>
            {getUi()}
        </AuthLayout>
    )
};

export default ForgotPassword;