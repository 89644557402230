import React, { MouseEventHandler, useState } from 'react'
import PageComponent from '../../../../components/Builder/PageBox/PageBox';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddNewPage from '../../../../assets/images/AddNewPage.svg'
import { IItinerary, IPage } from '../../../../helpers/Interfaces';
import { DropDownMenu } from '../../../../components/Builder/DropDownMenu/DropDownMenu';
import DeleteIcon from '@mui/icons-material/Delete';

const Page = ({ itinerary, selectedIndex, setSelectedPage, handleAddNewPage, onDeletePage, onRenamePage, createPage }:
     { onRenamePage: CallableFunction,
        onDeletePage: CallableFunction,
        itinerary: IItinerary,
        createPage: CallableFunction,
        selectedIndex?: number, 
        setSelectedPage: CallableFunction,
        handleAddNewPage: MouseEventHandler<HTMLButtonElement> 
    }) => {

    const [selectedPages, setSelectedPages] = useState<IPage[]>([]);
    const [selectAll, setSelectAll] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleSelectAll = () => {
        if (selectedPages.length >= 1) {
            setSelectedPages([]);
            setSelectAll(false);
        }
        else {
            setSelectAll(true);
        }
    };

    const handleOpenMenuClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleDeleteAll = () => {
        handleClose();
    };

    const menuItems = [
        { icon: <DeleteIcon />, action: handleDeleteAll, text: 'Delete All' }
    ]

    return (
        <div className='tab-main-container '>
            <div className="builder-page">
                <div className='select-all-row'>
                    {/* <div className='select-all-container'>
                        <input
                            type="checkbox"
                            checked={selectAll}
                            onChange={() => {
                                handleSelectAll();
                            }}
                        /><div className='select-all'> Select All </div>
                    </div>
                    <button className="ellipsis-button" onClick={handleOpenMenuClick}><MoreVertIcon /></button> */}
                </div>
                <div className="page-list">
                    {itinerary?.pages?.map((page) => (
                        <PageComponent
                            isChecked={page.id === selectedIndex}
                            key={page.id}
                            pageName={page.name}
                            createPage={() => createPage(page.id)}
                            onSelect={() => setSelectedPage(page.id)}
                            onDelete={() => onDeletePage(page.id)}
                            onRename={(val:string)=> onRenamePage(page.id, val)}
                        />
                    ))}
                    <div className='add-new-container'>
                        <button onClick={handleAddNewPage}><img src={AddNewPage} alt='addNew' /></button>
                    </div>
                </div>
            </div >
            <div className='feedback'>HAVE IDEAS ON HOW WE CAN IMPROVE? <button className='feedback-button'>SHARE YOUR FEEDBACK</button></div>
            <DropDownMenu anchorEl={anchorEl} handleClose={handleClose} open={open} menuItems={menuItems} />
        </div>
    );
}

export default Page