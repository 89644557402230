const AssetsTitle = () => {
    return (<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.95964 4.54036V1.70703C8.95964 1.08203 8.69297 0.832031 8.03047 0.832031H6.34714C5.68464 0.832031 5.41797 1.08203 5.41797 1.70703V4.54036C5.41797 5.16536 5.68464 5.41536 6.34714 5.41536H8.03047C8.69297 5.41536 8.95964 5.16536 8.95964 4.54036Z" fill="#E27C37" />
        <path d="M4.58464 5.45703V8.29036C4.58464 8.91536 4.31797 9.16536 3.65547 9.16536H1.97214C1.30964 9.16536 1.04297 8.91536 1.04297 8.29036V5.45703C1.04297 4.83203 1.30964 4.58203 1.97214 4.58203H3.65547C4.31797 4.58203 4.58464 4.83203 4.58464 5.45703Z" fill="#E27C37" />
        <path d="M8.95964 8.29167V7.125C8.95964 6.5 8.69297 6.25 8.03047 6.25H6.34714C5.68464 6.25 5.41797 6.5 5.41797 7.125V8.29167C5.41797 8.91667 5.68464 9.16667 6.34714 9.16667H8.03047C8.69297 9.16667 8.95964 8.91667 8.95964 8.29167Z" fill="#E27C37" />
        <path d="M4.58464 2.8737V1.70703C4.58464 1.08203 4.31797 0.832031 3.65547 0.832031H1.97214C1.30964 0.832031 1.04297 1.08203 1.04297 1.70703V2.8737C1.04297 3.4987 1.30964 3.7487 1.97214 3.7487H3.65547C4.31797 3.7487 4.58464 3.4987 4.58464 2.8737Z" fill="#E27C37" />
    </svg>
    )
}

export default AssetsTitle