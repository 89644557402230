import { $getSelection, $isRangeSelection, LexicalCommand, createCommand, $isTextNode, ParagraphNode, LexicalEditor, $createParagraphNode, $isParagraphNode, $isElementNode, $createTextNode, TextNode, $createRangeSelection, $setSelection, $insertNodes, $isRootOrShadowRoot } from 'lexical';
import { ListItemNode } from '@lexical/list';
import { $isHeadingNode, $createHeadingNode, $isQuoteNode, $createQuoteNode } from '@lexical/rich-text'
import { LinkNode, $createLinkNode, $isLinkNode } from '@lexical/link'
import { $wrapNodeInElement } from "@lexical/utils";
import { $createImageNode } from '../LexicalCustom/ImageNode';

// Define the command
export const CHANGE_TEXT_COLOR_COMMAND: LexicalCommand<string> = createCommand();
export const CHANGE_TEXT_SIZE_COMMAND: LexicalCommand<string> = createCommand();
export const CHANGE_FONT_FAMILY_COMMAND: LexicalCommand<string> = createCommand();
export const CHANGE_LINE_HIGHT_COMMAND: LexicalCommand<string> = createCommand();
export const CHANGE_LETTER_SPACING_COMMAND: LexicalCommand<string> = createCommand();
export const CHANGE_PARAGRAPH_SPACING_COMMAND: LexicalCommand<string> = createCommand();
export const CHANGE_TEXT_UPPERCASE_Command: LexicalCommand<string> = createCommand();
export const CHANGE_TEXT_LOWERCASE_COMMAND: LexicalCommand<string> = createCommand();
export const CHANGE_TEXT_CAPITALIZE_COMMAND: LexicalCommand<string> = createCommand();
export const CHANGE_SUPPER_SCRIPT_COMMAND: LexicalCommand<string> = createCommand();
export const CHANGE_WORD_SPACING_COMMAND: LexicalCommand<string> = createCommand();
export const CHANGE_SUB_SCRIPT_COMMAND: LexicalCommand<string> = createCommand();
export const INSERT_ORDERED_LIST_COMMAND: LexicalCommand<string> = createCommand();
export const INSERT_UNORDERED_LIST_COMMAND: LexicalCommand<string> = createCommand();
export const CHANGE_BACKGROUND_TEXT_COLOR_COMMAND: LexicalCommand<string> = createCommand();
export const CHANGE_HEADER_COMMAND: LexicalCommand<string> = createCommand();
export const INSERT_CHECKED_LIST_COMMAND: LexicalCommand<string> = createCommand();
export const CHANGE_QUOTE_COMMAND: LexicalCommand<string> = createCommand();
export const INSERT_LINK_COMMAND: LexicalCommand<string> = createCommand();
export const CLEAR_FORMATTING_COMMAND: LexicalCommand<string> = createCommand();
export const ADD_IMAGE_COMMAND: LexicalCommand<string> = createCommand();
export const INSERT_IMAGE_COMMAND: LexicalCommand<string> = createCommand("INSERT_IMAGE_COMMAND");


// Add Image in to the editor area
export function insertImage(payload: any) {
  const imageNode = $createImageNode(payload);
  $insertNodes([imageNode]);
  if ($isRootOrShadowRoot(imageNode.getParentOrThrow())) {
    $wrapNodeInElement(imageNode, $createParagraphNode).selectEnd();
  }
  return true;
}

// Apply the text color to the selected text
export function applyTextColor(color: string): void {
  const selection = $getSelection();
  if ($isRangeSelection(selection)) {
    const selectedNodes = selection.extract();
    selectedNodes.forEach((node) => {
      if ($isTextNode(node)) {
        const textNode = node;
        const currentStyle = node.getStyle();

        const newStyle = currentStyle ? `${currentStyle}; color: ${color};` : `color: ${color};`;
        textNode.setStyle(newStyle);
      }
    });
  }
}

// Apply the text size to the selected text
export function applyTextSize(size: string): void {
  const selection = $getSelection();
  if ($isRangeSelection(selection)) {
    const selectedNodes = selection.extract();
    selectedNodes.forEach((node) => {
      if ($isTextNode(node)) {
        const textNode = node;
        const currentStyle = node.getStyle();
        const newStyle = currentStyle ? `${currentStyle}; font-size: ${size};` : `font-size: ${size};`;
        textNode.setStyle(newStyle);
      }
    });
  }
}

// Change the text family to the selected text
export function applyFontFamily(font: string): void {
  const selection = $getSelection();
  if ($isRangeSelection(selection)) {
    const selectedNodes = selection.extract();
    selectedNodes.forEach((node) => {
      if ($isTextNode(node)) {
        const textNode = node;
        const currentStyle = node.getStyle();
        const newStyle = currentStyle ? `${currentStyle}; font-family: ${font};` : `font-family: ${font};`;
        textNode.setStyle(newStyle);
      }
    });
  }
}

// Change the line hight to the selected text
export function applyLineHight(font: string): void {
  const selection = $getSelection();
  if ($isRangeSelection(selection)) {
    const selectedNodes = selection.extract();
    selectedNodes.forEach((node) => {
      if ($isTextNode(node)) {
        const textNode = node;
        const currentStyle = node.getStyle();
        const newStyle = currentStyle ? `${currentStyle}; line-height: ${font} !important;` : `line-height: ${font}!important;`;
        textNode.setStyle(newStyle);
      }
    });
  }
}

// Change the letter spacing the selected text
export function applyLetterSpacing(font: string): void {
  const selection = $getSelection();
  if ($isRangeSelection(selection)) {
    const selectedNodes = selection.extract();
    selectedNodes.forEach((node) => {
      if ($isTextNode(node)) {
        const textNode = node;
        const currentStyle = node.getStyle();
        const newStyle = currentStyle ? `${currentStyle}; letter-spacing: ${font} !important;` : `letter-spacing: ${font}!important;`;
        textNode.setStyle(newStyle);
      }
    });
  }
}

// Change the letter spacing the selected text
export function applyParagraphSpacing(editor: LexicalEditor, spacing: string): void {
  editor.update(() => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      const selectedNodes = selection.extract();

      selectedNodes.forEach((node) => {
        if (node instanceof ParagraphNode) {
          const paragraphNode = node;
          const currentStyle = paragraphNode.getStyle();
          const newStyle = currentStyle
            ? `${currentStyle}; margin-bottom: ${spacing} !important;`
            : `margin-bottom: ${spacing} !important;`;
          paragraphNode.setStyle(newStyle);
        }
      });
    }
  });
}

// Change the letter spacing the selected text
export function applyTextCapitalize(): void {
  const selection = $getSelection();
  if ($isRangeSelection(selection)) {
    const selectedNodes = selection.extract();
    selectedNodes.forEach((node) => {
      if ($isTextNode(node)) {
        const textNode = node;
        const currentStyle = node.getStyle();
        const newStyle = currentStyle ? `${currentStyle}; text-transform: capitalize;` : `text-transform: capitalize;`;
        textNode.setStyle(newStyle);
      }
    });
  }
}

// Change the letter spacing the selected text
export function applyTextLowerCase(): void {
  const selection = $getSelection();
  if ($isRangeSelection(selection)) {
    const selectedNodes = selection.extract();
    selectedNodes.forEach((node) => {
      if ($isTextNode(node)) {
        const textNode = node;
        const currentStyle = node.getStyle();
        const newStyle = currentStyle ? `${currentStyle}; text-transform: lowercase;` : `text-transform: lowercase;`;
        textNode.setStyle(newStyle);
      }
    });
  }
}

// Change the letter spacing the selected text
export function applyTextUpperCase(): void {
  const selection = $getSelection();
  if ($isRangeSelection(selection)) {
    const selectedNodes = selection.extract();
    selectedNodes.forEach((node) => {
      if ($isTextNode(node)) {
        const textNode = node;
        const currentStyle = node.getStyle();
        const newStyle = currentStyle ? `${currentStyle}; text-transform: uppercase;` : `text-transform: uppercase;`;
        textNode.setStyle(newStyle);
      }
    });
  }
}

// Change the supper script the selected text
export function applySuperscript(): void {
  const selection = $getSelection();

  if ($isRangeSelection(selection)) {
    const selectedNodes = selection.extract();

    selectedNodes.forEach((node) => {
      if ($isTextNode(node)) {
        const currentStyle = node.getStyle();
        const isSuperscript = node.hasFormat('superscript');

        if (node.getTextContent() !== '') {
          if (isSuperscript) {
            node.toggleFormat('superscript');
          } else {
            node.toggleFormat('superscript');
          }

          node.setStyle(currentStyle);
          node.markDirty();
        }
      }
    });
  }
}

// Change the sub script the selected text
export function applySubscript(): void {
  const selection = $getSelection();

  if ($isRangeSelection(selection)) {
    const selectedNodes = selection.extract();

    selectedNodes.forEach((node) => {
      if ($isTextNode(node)) {
        const currentStyle = node.getStyle();
        const isSubscript = node.hasFormat('subscript');

        if (node.getTextContent() !== '') {
          if (isSubscript) {
            node.toggleFormat('subscript');
          } else {
            node.toggleFormat('subscript');
          }

          node.setStyle(currentStyle);
          node.markDirty();
        }
      }
    });
  }
}

// Change the word spacing the selected text
export function applyWordSpacing(spacing: string): void {
  const selection = $getSelection();
  if ($isRangeSelection(selection)) {
    const selectedNodes = selection.extract();
    selectedNodes.forEach((node) => {
      if ($isTextNode(node)) {
        const textNode = node;
        const currentStyle = node.getStyle();
        const newStyle = currentStyle
          ? `${currentStyle}; word-spacing: ${spacing} !important;`
          : `word-spacing: ${spacing} !important;`;
        textNode.setStyle(newStyle);
      }
    });
  }
}

export function applyBackgroundTextColor(color: string): void {
  const selection = $getSelection();

  if ($isRangeSelection(selection)) {
    const selectedNodes = selection.extract();

    selectedNodes.forEach((node) => {
      if ($isTextNode(node)) {
        const textNode = node;
        const currentStyle = node.getStyle();

        const newStyle = currentStyle ? `${currentStyle}; background-color: ${color};` : `background-color: ${color};`;

        textNode.setStyle(newStyle);
      }
    });
  }
}

export function isSelectionOrderedList(): boolean {
  const selection = $getSelection();

  if (!selection) {
    return false;
  }

  const nodes = selection.getNodes();

  if (!nodes) {
    return false;
  }

  return nodes.some((node) => node instanceof ListItemNode);
}

export function toggleHeading(headingTag: "h1" | "h2" | "h3") {
  const selection = $getSelection();

  if ($isRangeSelection(selection)) {
    const nodes = selection.extract();

    nodes.forEach((node) => {
      if (node instanceof TextNode) {
        if (node.__parent != "root") {
          const parent = node.getParent();

          if (parent && $isHeadingNode(parent)) {
            if (parent.getTag() === headingTag) {
              const paragraphNode = $createParagraphNode();
              parent.replace(paragraphNode);
              paragraphNode.append(...parent.getChildren());
            } else {
              const newHeadingNode = $createHeadingNode(headingTag);
              parent.replace(newHeadingNode);
              newHeadingNode.append(...parent.getChildren());
            }
          } else {
            const newHeadingNode = $createHeadingNode(headingTag);
            const parentNode = node.getParent();

            if (parentNode) {
              parentNode.replace(newHeadingNode);
              newHeadingNode.append(...parentNode.getChildren());
            } else {
              const paragraphNode = $createParagraphNode();
              node.replace(paragraphNode);
              paragraphNode.append(...nodes);
            }
          }
        }
      }
    });
  }
}

export function toggleQuote() {
  const selection = $getSelection();

  if ($isRangeSelection(selection)) {
    const nodes = selection.extract();

    nodes.forEach((node) => {
      if (node.__parent != "root") {
        const parent = node.getParent();

        if (parent && $isElementNode(parent)) {
          if ($isQuoteNode(parent)) {
            const paragraphNode = $createParagraphNode();
            parent.replace(paragraphNode);
            parent.getChildren().forEach(child => {
              paragraphNode.append(child);
            });
          } else {
            const quoteNode = $createQuoteNode();
            parent.replace(quoteNode);
            parent.getChildren().forEach(child => {
              quoteNode.append(child);
            });
          }
        }
      }
    });
  }
}

export function insertLink(url: string) {
  const selection = $getSelection();

  if ($isRangeSelection(selection)) {
    const nodes = selection.extract();
    nodes.forEach((node) => {
      if (node.getParent() instanceof LinkNode) {
        const linkNode = node.getParent();
        const textNode = $createTextNode();
        linkNode!.getChildren().forEach((childNode) => {
          const childTextContent = childNode.getTextContent();
          textNode.setTextContent(textNode.getTextContent() + childTextContent);
        });

        linkNode!.replace(textNode);

        if ($isRangeSelection(selection)) {
          const rangeSelection = $createRangeSelection();
          rangeSelection.anchor.set(textNode.getKey(), 0, 'text');
          rangeSelection.focus.set(textNode.getKey(), textNode.getTextContentSize(), 'text');
          $setSelection(rangeSelection);
        }
      }
      else if (node instanceof TextNode) {
        const parent = node.getParent();
        if (!parent || !(parent instanceof LinkNode)) {
          const linkNode = $createLinkNode(url);
          linkNode.setTitle(url);
          linkNode.setStyle(node.__style);
          const textNode = $createTextNode(node.getTextContent());
          linkNode.append(textNode);
          node.replace(linkNode);
          linkNode.setURL(url);
        }
      }
    });
  }
}

export function clearFormatting() {
  const selection = $getSelection();
  if ($isRangeSelection(selection)) {
    const nodes = selection.extract();

    nodes.forEach((node) => {
      if ($isTextNode(node)) {
        node.setFormat(0);
        node.setStyle("");
      } else if ($isElementNode(node)) {
        node.setStyle("");
        const children = node.getChildren();
        children.forEach((child) => {
          if ($isTextNode(child)) {
            child.setFormat(0);
            child.setStyle("");
          }
        });
      }
    });
  }
}
