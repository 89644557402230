import React , {useState} from 'react';
import { CircularProgress } from '@mui/material';
import eyeImg from '../../assets/images/eye.svg';
import eyeClose from '../../assets/images/eyeClose.svg';

interface PasswordResetFormProps {
    newPassword: string;
    confirmPassword: string;
    loading: boolean;
    onChangeNewPassword: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeConfirmPassword: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void; 
}

const PasswordResetForm: React.FC<PasswordResetFormProps> = ({newPassword,confirmPassword,loading,onChangeNewPassword,onChangeConfirmPassword,onSubmit}) => {

    const [showNewPassword,setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const toggleNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }

    return (
        <form onSubmit={onSubmit} className='password-reset-form'>
            <div className='form-group'>
                <input
                    required
                    type={showNewPassword ? 'text' : 'password'}
                    className='form-control'
                    value={newPassword}
                    placeholder='Enter new password'
                    onChange={onChangeNewPassword}
                />
                <button onClick={toggleNewPasswordVisibility} className='password-toggle' type='button'> <img src={showNewPassword ? eyeClose : eyeImg} alt='' /> </button>
            </div>
            <div className='form-group'>
                <input
                    required
                    type={showConfirmPassword ? 'text' : 'password'}
                    className='form-control'
                    placeholder='Confirm new password'
                    value={confirmPassword}
                    onChange={onChangeConfirmPassword}
                />
               <button onClick={toggleConfirmPasswordVisibility} className='password-toggle' type='button'> <img src={showConfirmPassword ? eyeClose : eyeImg} alt='' /> </button>
            </div>
            <button type='submit' className='submit fz-16 primary-font fw-600 ttu c-white'>
                {loading ? <CircularProgress color='inherit' size={28} /> : "Reset Password"}
            </button>
        </form>
    );
};

export default PasswordResetForm;
