import { useState } from 'react'
import RedoSvg from '../../../../assets/images/builder/redoSvg.svg'
import UndoSvg from '../../../../assets/images/builder/undoSvg.svg'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import ToolbarPlugins from '../plugins/ToolbarPlugin';
import TableChartIcon from '@mui/icons-material/TableChart';
import PhotoIcon from '@mui/icons-material/Photo';
import { DropDownMenu } from '../../../../components/Builder/DropDownMenu/DropDownMenu';
import BorderlessButton from '../../../../components/ButtonWithoutBorder/ButtonWithoutBorder';
import PlusWithBorder from '../../../../assets/images/buttons/PlusWithBorder';
import InsertImage from '../../../../components/Builder/InsertImage/InsertImage';
import { IIconList } from '../../../../helpers/Interfaces';
import AddTable from '../../../../components/Builder/AddTable/AddTable';
import { ZoomIn, ZoomOut } from '@mui/icons-material';
import TabDivider from '../../../../assets/images/builder/TabDivider';

const MenuControls = ({ selectedPageIndex, scale, setScale }: { selectedPageIndex?: number, scale: number, setScale: CallableFunction }) => {
    const [openInsertImageModal, setOpenInsertImageModal] = useState<boolean>(false);
    const [openInsertTableModal, setOpenInsertTableModal] = useState<boolean>(false);
    const [editor] = useLexicalComposerContext();
    const toolbar = new ToolbarPlugins({ editor });
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleImage = () => {
        setOpenInsertImageModal(true);
        handleClose();
    };

    const handleTable = () => {
        setOpenInsertTableModal(true);
        handleClose();
    };

    const handleScaler = (value: string) => {
        if (value === ('zoomIn' as any) && scale !== (1.5)) {
            setScale(scale + 0.025);
        } else if (value === ('zoomOut' as any) && scale >= (0.5)) {
            setScale(scale - 0.025);
        }
    }

    const menuItems: IIconList[] = [
        { icon: <PhotoIcon />, action: handleImage, text: 'Image' },
        // { icon: <PhotoIcon />, action: handleInlineImage, text: 'Inline Image' },
        // { icon: <TableChartIcon />, action: handleTable, text: 'Table' },
        // { icon: <ViewWeek />, action: handleColumn, text: 'Column Layout' }
    ];

    return (
        <div className='editor-controls'>
            <div className="add-new">
                {selectedPageIndex ? (<BorderlessButton label={('addNew')} icon={<PlusWithBorder />} onClick={handleClick} />) : (<></>)}
            </div>
            <DropDownMenu anchorEl={anchorEl} handleClose={handleClose} open={open} menuItems={menuItems} />
            <div className="controls-outer">
                <img onClick={toolbar.undo} src={UndoSvg} alt="" />
                <img onClick={toolbar.redo} src={RedoSvg} alt="" />
                <button disabled={scale === 1.5} onClick={() => handleScaler('zoomIn')}><ZoomIn /></button>
                <button disabled={scale <= 0.5} onClick={() => handleScaler('zoomOut')}><ZoomOut /></button>
            </div>
            <InsertImage open={openInsertImageModal} close={() => setOpenInsertImageModal(false)} addImage={toolbar.addImage} />
            <AddTable open={openInsertTableModal} close={() => setOpenInsertTableModal(false)} addTable={toolbar.addTable} />
        </div>
    )
}

export default MenuControls