import React, { useState } from 'react';
import PasswordResetForm from './PasswordResetForm';

type PasswordResetProps =  {
    email: string;
    handleResetPassword: (newPassword: string) => Promise<void>;
}

const PasswordReset: React.FC<PasswordResetProps> = ({
    email,
    handleResetPassword
}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');


    const handleSubmit = async (e:React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            return; 
        }
        setLoading(true);
        try {
            await handleResetPassword(newPassword);
        } catch (error) {
            console.error("Password reset failed", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <PasswordResetForm
            newPassword={newPassword}
            confirmPassword={confirmPassword}
            loading={loading}
            onChangeNewPassword={(e) => setNewPassword(e.target.value)}
            onChangeConfirmPassword={(e) => setConfirmPassword(e.target.value)}
            onSubmit={handleSubmit} 
        />
    );
};

export default PasswordReset;
