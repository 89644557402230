import React from 'react';
import OtpVerificationForm from './OtpVerificationForm';

type OtpVerificationProps = {
    email: string;
    handleOtpSubmit: (otp: string) => void; 
    loading:boolean;
};

const OtpVerification = ({ email, handleOtpSubmit,loading }: OtpVerificationProps) => {

    
    return (
            <OtpVerificationForm email={email} onOtpSubmit={handleOtpSubmit} loading={loading} />
    );
};

export default OtpVerification;
