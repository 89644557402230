import { Modal } from "@mui/material";
import "./InitializeBuilder.scss";
import { Close } from "@mui/icons-material";
import TextIcon from "../../../assets/images/builder/text_black.svg";
import OrientationIcon from "../../../assets/images/builder/orientation_black.svg";
import PageSizeIcon from "../../../assets/images/builder/pageSize_black.svg";
import ArrivalIcon from "../../../assets/images/builder/arrival.svg";
import DepartureIcon from "../../../assets/images/builder/departure.svg";
import PageTypeIcon from "../../../assets/images/builder/file-filled.svg";
import EuroIcon from "../../../assets/images/builder/euro.svg";

import UsersIcon from "../../../assets/images/builder/UsersIcon";
import SingleUserIcon from "../../../assets/images/builder/single-user.svg";
import InfoIcon from "../../../assets/images/builder/info.svg";
import EIcon from "../../../assets/images/builder/e-icon.svg";
import SearchIcon from "../../../assets/images/builder/search.svg";
import PagesIcon from "../../../assets/images/builder/pages.svg";
import CalenderIcon from "../../../assets/images/builder/calender-2.svg";
import ShapesIcon from "../../../assets/images/builder/shapes.svg";
import LabelIcon from "../../../assets/images/builder/label.svg";

import TextInput from "../TextInput/TextInput";
import Dropdown from "../DropDown/DropDown";
import { FormEvent, useEffect, useState } from "react";
import { IItinerary } from "../../../helpers/Interfaces";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import CustomMultiSelect from "../../Mui/CustomMultiSelect/CustomMultiSelect";
import CustomDateInput from "../../Mui/CustomDateInput/CustomDateInput";
import CustomTextField from "../../Mui/CustomTextField/CustomTextField";
import SecondaryButton from "../../SecondaryButton/SecondaryButton";
import PluseSvg from "../../../assets/images/buttons/PluseSvg";
import BuilderIcon from "../../../assets/images/builder/BuilderIcon";
import SummeryIcon from "../../../assets/images/builder/SummeryIcon";
import TransparentButton from "../../TransparentButton/TransparentButton";
import UserIcon from "../../../assets/images/builder/UserIcon";

// sample arrays
const pageTemplates = [
  { label: "Welcome to Explor", id: "t1" },
  { label: "Colombo", id: "t2" },
  { label: "Kandy", id: "t3" },
];
const portLocations = [
  { label: "Bandaranayake International Airport (CMB)", id: "t1" },
  { label: "Colombo", id: "t2" },
  { label: "Gall", id: "t3" },
  { label: "Yala", id: "t4" },
];
const currency = [{ label: "EUR", value: "euro" }];
const passengerType = [
  { label: "Adult", value: "Adult" },
  { label: "Child", value: "Child" },
];
const iconsList = [{ label: "\\f07c", value: "folder" }];

type InitializeBuilderProps = {
  open: boolean;
  close: CallableFunction;
  itinerary?: IItinerary;
  setItinerary: CallableFunction;
};

const InitializeBuilder = (props: InitializeBuilderProps) => {
  const [formData, setFormData] = useState<{ title?: string; orientation?: "vertical" | "horizontal"; pageSize?: { value: string; label: string } }>({ pageSize: { value: "420x594", label: "A2 420 x 594 mm" } });
  const options = [
    { value: "420x594", label: "A2 420 x 594 mm" },
    { value: "297x420", label: "A3 297 x 420 mm" },
    { value: "210x297", label: "A4 210 x 297 mm" },
  ];
  useEffect(() => {
    if (props.itinerary) {
      setFormData({ title: props.itinerary.name, pageSize: options.find(t => t.value === props.itinerary?.resolution) ?? options[0] })
    }
  }, [props.itinerary]);

  const onSubmit = () => {
    const newItinerary = props.itinerary;
    if (newItinerary) {
      newItinerary.name = formData.title ?? '';
      newItinerary.resolution = formData.pageSize?.value ?? '420x594';
      props.setItinerary({ ...newItinerary });
    }
    props.close();
  };

  return (
    <Modal open={props.open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <div className="initializer-container">
        {/* <div className="close-outer">
          <button onClick={() => props.close()}>
            <Close />
          </button>
        </div> */}
        <form className="initializer-form" >
          <span className="header-title">Create New Document</span>
          <div className="builder-inner">
            <div className="lft">
              <div>
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                    <Typography>
                      <img src={PageTypeIcon} alt="" />
                      Document Settings
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="section-outer">
                      <div className="section-header">
                        <img src={TextIcon} alt="Text Icon" />
                        <span>Document Title</span>
                      </div>
                      <TextInput value={formData?.title} onChange={(val: any) => setFormData({ ...formData, title: val.target.value })} placeholder="New Itinerary for Hugo Mille #4" />
                    </div>
                    <div className="section-outer">
                      <div className="section-header">
                        <img src={OrientationIcon} alt="Text Icon" />
                        <span>Orientation</span>
                      </div>
                      <div className="orientation-outer">
                        <div onClick={() => { }} className={`orientation active ${formData.orientation === "horizontal" ? "active" : ""}`}>
                          {/* <div onClick={() => setFormData({ ...formData, orientation: 'horizontal' })} className={`orientation active ${formData.orientation === 'horizontal' ? 'active' : ''}`}> */}
                          <div className="horizontal-icon" />
                          <span>Horizontal</span>
                        </div>
                        <div onClick={() => { }} className={`orientation ${formData.orientation === "vertical" ? "active" : ""}`}>
                          {/* <div onClick={() => setFormData({ ...formData, orientation: 'vertical' })} className={`orientation ${formData.orientation === 'vertical' ? 'active' : ''}`}>  */}
                          <div className="vertical-icon" />
                          <span>Vertical</span>
                        </div>
                      </div>
                    </div>
                    <div className="section-outer">
                      <div className="section-header">
                        <img src={PageSizeIcon} alt="Text Icon" />
                        <span>Page Size</span>
                      </div>
                      <Dropdown defaultValue={formData.pageSize?.label} onSelect={(val) => setFormData({ ...formData, pageSize: val })} options={options} />
                    </div>
                  </AccordionDetails>
                </Accordion>
                <hr />
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
                    <Typography>
                      <img src={PageTypeIcon} alt="" /> Document Outline
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className="accordian-details">
                    <div className="inner">
                      <div className="number">
                        <div className="num">1</div>
                      </div>
                      <div className="form-control-outer">
                        <CustomTextField defaultValue="Page 01" label="Page 01" size="small" helperText="" onChange={() => { }} />
                      </div>
                      <div className="form-control-outer">
                        <CustomMultiSelect required helperText="required" label="Template (Optional)" options={pageTemplates} onSelect={() => { }} />
                      </div>
                    </div>
                    <div className="inner">
                      <div className="number">
                        <div className="num">2</div>
                      </div>
                      <div className="form-control-outer">
                        <CustomTextField defaultValue="" label="Page 02" size="small" helperText="" onChange={() => { }} />
                      </div>
                      <div className="form-control-outer">
                        <CustomMultiSelect required helperText="required" label="Template (Optional)" options={pageTemplates} onSelect={() => { }} />
                      </div>
                    </div>
                    <div className="inner">
                      <div className="number">
                        <div className="num">3</div>
                      </div>
                      <div className="form-control-outer">
                        <CustomTextField defaultValue="" label="Page 03" size="small" helperText="" onChange={() => { }} />
                      </div>
                      <div className="form-control-outer">
                        <CustomMultiSelect required helperText="required" label="Template (Optional)" options={pageTemplates} onSelect={() => { }} />
                      </div>
                    </div>
                    <div className="inner">
                      <SecondaryButton label="Add Another Page" icon={<PluseSvg />} onClick={() => { }} />
                    </div>
                  </AccordionDetails>
                </Accordion>
                <hr />
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-content" id="panel3-header">
                    <Typography>
                      <img src={PageTypeIcon} alt="" /> Detailed Itinerary
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className="accordian-details detailed-itinerary">
                    <div className="inner">
                      <div className="number arrival-number">
                        <div className="num">
                          <img src={ArrivalIcon} alt="" />
                        </div>
                      </div>
                      <div className="form-control-outer fc-1">
                        <CustomMultiSelect required helperText="required" label="Port of Arrival" options={portLocations} onSelect={() => { }} />
                      </div>
                      <div className="form-control-outer fc-2">
                        <CustomDateInput defaultValue={new Date().toISOString()} helperText="" label="Date of Arrival" onChange={() => { }} />
                      </div>
                      <div className="form-control-outer fc-3">
                        <p></p>
                      </div>
                    </div>
                    <div className="inner">
                      <div className="number">
                        <div className="num">A</div>
                      </div>
                      <div className="form-control-outer fc-1">
                        <CustomMultiSelect required helperText="required" label="Location" options={portLocations} onSelect={() => { }} />
                      </div>
                      <div className="form-control-outer fc-2">
                        <CustomDateInput defaultValue={new Date().toISOString()} helperText="" label="Date Range" onChange={() => { }} />
                      </div>
                      <div className="form-control-outer fc-3">
                        <p>2 Nights</p>
                      </div>
                    </div>
                    <div className="inner">
                      <div className="number">
                        <div className="num">B</div>
                      </div>
                      <div className="form-control-outer fc-1">
                        <CustomMultiSelect required helperText="required" label="Location" options={portLocations} onSelect={() => { }} />
                      </div>
                      <div className="form-control-outer fc-2">
                        <CustomDateInput defaultValue={new Date().toISOString()} helperText="" label="Date Range" onChange={() => { }} />
                      </div>
                      <div className="form-control-outer fc-3">
                        <p>1 Night</p>
                      </div>
                    </div>
                    <div className="inner">
                      <div className="number">
                        <div className="num">C</div>
                      </div>
                      <div className="form-control-outer fc-1">
                        <CustomMultiSelect required helperText="required" label="Location" options={portLocations} onSelect={() => { }} />
                      </div>
                      <div className="form-control-outer fc-2">
                        <CustomDateInput defaultValue={new Date().toISOString()} helperText="" label="Date Range" onChange={() => { }} />
                      </div>
                      <div className="form-control-outer fc-3">
                        <p>2 Nights</p>
                      </div>
                    </div>
                    <div className="inner">
                      <div className="number">
                        <div className="num">D</div>
                      </div>
                      <div className="form-control-outer fc-1">
                        <CustomMultiSelect required helperText="required" label="Location" options={portLocations} onSelect={() => { }} />
                      </div>
                      <div className="form-control-outer fc-2">
                        <CustomDateInput defaultValue={new Date().toISOString()} helperText="" label="Date Range" onChange={() => { }} />
                      </div>
                      <div className="form-control-outer fc-3">
                        <p>2 Nights</p>
                      </div>
                    </div>
                    <div className="inner">
                      <div className="number">
                        <div className="num">-</div>
                      </div>
                      <div className="form-control-outer fc-1">
                        <CustomMultiSelect required helperText="required" label="Choose or Add Location" options={portLocations} onSelect={() => { }} />
                      </div>
                      <div className="form-control-outer fc-2"></div>
                      <div className="form-control-outer fc-3">
                        <p></p>
                      </div>
                    </div>
                    <div className="inner btn-outer">
                      <SecondaryButton label="Add Another Location" icon={<PluseSvg />} onClick={() => { }} />
                    </div>
                    <div className="inner">
                      <div className="number arrival-number">
                        <div className="num">
                          <img src={DepartureIcon} alt="" />
                        </div>
                      </div>
                      <div className="form-control-outer fc-1">
                        <CustomMultiSelect required helperText="required" label="Port of Departure" options={portLocations} onSelect={() => { }} />
                      </div>
                      <div className="form-control-outer fc-2">
                        <CustomDateInput defaultValue={new Date().toISOString()} helperText="" label="Date of Departure" onChange={() => { }} />
                      </div>
                      <div className="form-control-outer fc-3">
                        <p></p>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <hr />
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4-content" id="panel4-header">
                    <Typography>
                      <img src={PageTypeIcon} alt="" /> Charges & Costs
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className="accordian-details detailed-itinerary">
                    <div className="top-inner">
                      <div className="left">
                        <div className="upper-bts-content">
                          <TransparentButton icon={<UsersIcon />} label="Group" onClick={() => { }} />
                          <TransparentButton active icon={<UserIcon />} label="per Person" onClick={() => { }} />
                        </div>
                        <div className="img-dropdown">
                          <img src={EuroIcon} alt="" />
                          <Dropdown defaultValue={currency[0].label} onSelect={() => { }} options={currency} />
                        </div>
                      </div>
                      <div className="rht">
                        <div className="e-sec">
                          <div className="num">
                            <img src={EIcon} alt="" />
                            <span>7,909.20</span>
                          </div>
                          <img src={InfoIcon} alt="" />
                        </div>
                      </div>
                    </div>

                    <div className="inner">
                      <div className="number">
                        <div className="num">A</div>
                      </div>
                      <div className="form-control-outer fc-4">
                        <Dropdown defaultValue={passengerType[0].label} onSelect={() => { }} options={passengerType} />
                      </div>
                      <div className="form-control-outer">
                        <CustomTextField defaultValue="Adult - Twin Sharing" label="Category Label" size="small" helperText="" onChange={() => { }} />
                      </div>
                      <div className="form-control-outer fc-4">
                        <CustomTextField defaultValue="1950.00" label="Value" size="small" helperText="" onChange={() => { }} />
                      </div>
                    </div>

                    <div className="inner">
                      <div className="number">
                        <div className="num">B</div>
                      </div>
                      <div className="form-control-outer fc-4">
                        <Dropdown defaultValue={passengerType[1].label} onSelect={() => { }} options={passengerType} />
                      </div>
                      <div className="form-control-outer">
                        <CustomTextField defaultValue="Adult - Twin Sharing" label="Category Label" size="small" helperText="" onChange={() => { }} />
                      </div>
                      <div className="form-control-outer fc-4">
                        <CustomTextField defaultValue="1950.00" label="Value" size="small" helperText="" onChange={() => { }} />
                      </div>
                    </div>

                    <div className="inner btn-outer">
                      <SecondaryButton label="Add Another Price Category" icon={<PluseSvg />} onClick={() => { }} />
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
            <div className="riht">
              <div className="icon-search">
                <div className="img-dropdown">
                  <i className="fa-regular fa-folder-open f-icon"></i>
                  <i className="fa-solid fa-chevron-down d-arrow"></i>
                </div>

                <div className="input-outer">
                  <input type="text" placeholder="Search Keywords" />
                  <button>
                    <img src={SearchIcon} alt="" />
                  </button>
                </div>
              </div>
              <div className="line-header">
                <span>PAGES</span>
              </div>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4-content" id="panel4-header">
                  <Typography className="ach-head">
                    <div className="ls"><img src={PagesIcon} alt="" /> <span className="title">Welcome to Explor</span> </div> <div className="plus-icon">
                      <PluseSvg />
                    </div>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="accordian-details detailed-itinerary">
                  <div className="inner-row">
                    <div className="item"><img src={ShapesIcon} alt="" /> <span className="txt">General</span></div>
                    <div className="item"><img src={ShapesIcon} alt="" /> <span className="txt">Aug. 10, 2023</span></div>
                    <div className="item"><img src={ShapesIcon} alt="" /> <span className="txt">colombo, introduction</span></div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4-content" id="panel4-header">
                  <Typography className="ach-head">
                    <div className="ls"><img src={PagesIcon} alt="" /> <span className="title">Colombo City</span> </div> <div className="plus-icon">
                      <PluseSvg />
                    </div>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="accordian-details detailed-itinerary">
                  <div className="inner-row">
                    <div className="item"><img src={ShapesIcon} alt="" /> <span className="txt">General</span></div>
                    <div className="item"><img src={ShapesIcon} alt="" /> <span className="txt">Aug. 10, 2023</span></div>
                    <div className="item"><img src={ShapesIcon} alt="" /> <span className="txt">colombo, introduction</span></div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4-content" id="panel4-header">
                  <Typography className="ach-head">
                    <div className="ls"><img src={PagesIcon} alt="" /> <span className="title">Kandy</span> </div> <div className="plus-icon">
                      <PluseSvg />
                    </div>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="accordian-details detailed-itinerary">
                  <div className="inner-row">
                    <div className="item"><img src={ShapesIcon} alt="" /> <span className="txt">General</span></div>
                    <div className="item"><img src={ShapesIcon} alt="" /> <span className="txt">Aug. 10, 2023</span></div>
                    <div className="item"><img src={ShapesIcon} alt="" /> <span className="txt">colombo, introduction</span></div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4-content" id="panel4-header">
                  <Typography className="ach-head">
                    <div className="ls"><img src={PagesIcon} alt="" /> <span className="title">Camping in the Wilderness</span> </div> <div className="plus-icon">
                      <PluseSvg />
                    </div>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="accordian-details detailed-itinerary">
                  <div className="inner-row">
                    <div className="item"><img src={ShapesIcon} alt="" /> <span className="txt">General</span></div>
                    <div className="item"><img src={ShapesIcon} alt="" /> <span className="txt">Aug. 10, 2023</span></div>
                    <div className="item"><img src={ShapesIcon} alt="" /> <span className="txt">colombo, introduction</span></div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4-content" id="panel4-header">
                  <Typography className="ach-head">
                    <div className="ls"><img src={PagesIcon} alt="" /> <span className="title">Birdwatching on Victoria Dam</span> </div> <div className="plus-icon">
                      <PluseSvg />
                    </div>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="accordian-details detailed-itinerary">
                  <div className="inner-row">
                    <div className="item"><img src={ShapesIcon} alt="" /> <span className="txt">General</span></div>
                    <div className="item"><img src={ShapesIcon} alt="" /> <span className="txt">Aug. 10, 2023</span></div>
                    <div className="item"><img src={ShapesIcon} alt="" /> <span className="txt">colombo, introduction</span></div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4-content" id="panel4-header">
                  <Typography className="ach-head">
                    <div className="ls"><img src={PagesIcon} alt="" /> <span className="title">Cultural Triangle Tour - One Day</span> </div> <div className="plus-icon">
                      <PluseSvg />
                    </div>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="accordian-details detailed-itinerary">
                  <div className="inner-row">
                    <div className="item"><img src={ShapesIcon} alt="" /> <span className="txt">General</span></div>
                    <div className="item"><img src={ShapesIcon} alt="" /> <span className="txt">Aug. 10, 2023</span></div>
                    <div className="item"><img src={ShapesIcon} alt="" /> <span className="txt">colombo, introduction</span></div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4-content" id="panel4-header">
                  <Typography className="ach-head">
                    <div className="ls"><img src={PagesIcon} alt="" /> <span className="title">Sigiriya & Pidurangala</span> </div> <div className="plus-icon">
                      <PluseSvg />
                    </div>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="accordian-details detailed-itinerary">
                  <div className="inner-row">
                    <div className="item"><img src={ShapesIcon} alt="" /> <span className="txt">General</span></div>
                    <div className="item"><img src={ShapesIcon} alt="" /> <span className="txt">Aug. 10, 2023</span></div>
                    <div className="item"><img src={ShapesIcon} alt="" /> <span className="txt">colombo, introduction</span></div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>

          <button onClick={onSubmit} className="initializer-button">Create New Itinerary</button>
        </form>
      </div>
    </Modal>
  );
};

export default InitializeBuilder;
