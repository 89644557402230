const PluseSvg = () => (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_40_427)">
        <path d="M15 1.66667C16.8383 1.66667 18.3333 3.16167 18.3333 5V15C18.3333 16.8383 16.8383 18.3333 15 18.3333H5C3.16167 18.3333 1.66667 16.8383 1.66667 15V5C1.66667 3.16167 3.16167 1.66667 5 1.66667H15ZM15 0H5C2.23833 0 0 2.23833 0 5V15C0 17.7617 2.23833 20 5 20H15C17.7617 20 20 17.7617 20 15V5C20 2.23833 17.7617 0 15 0Z" fill="black" />
        <path d="M9.99935 15C9.53935 15 9.16602 14.6275 9.16602 14.1667V5.83333C9.16602 5.37333 9.53935 5 9.99935 5C10.4593 5 10.8327 5.37333 10.8327 5.83333V14.1667C10.8327 14.6275 10.4593 15 9.99935 15Z" fill="black" />
        <path d="M5 10.0003C5 9.54033 5.3725 9.16699 5.83333 9.16699H14.1667C14.6267 9.16699 15 9.54033 15 10.0003C15 10.4603 14.6267 10.8337 14.1667 10.8337H5.83333C5.3725 10.8337 5 10.4603 5 10.0003Z" fill="black" />
    </g>
    <defs>
        <clipPath id="clip0_40_427">
            <rect width="20" height="20" fill="white" />
        </clipPath>
    </defs>
</svg>);

export default PluseSvg