import { MouseEventHandler, ReactElement } from 'react';
import './ButtonWithoutBorder.scss';

type BorderlessButtonProps = {
    onClick: MouseEventHandler<HTMLButtonElement>,
    label: string,
    icon?: ReactElement
}

const BorderlessButton = ({ onClick, label, icon }: BorderlessButtonProps) => {
    return (
        <button onClick={onClick} className="drop-button" type="button">
            {icon
                ? (<span className="icon">
                    {icon}
                </span>)
                : (<></>)}
            <span className="txt fz-16 ttu primary-font">{label}</span>
        </button>
    )
}

export default BorderlessButton