import dayjs from 'dayjs';
import { DropdownItemProps } from "../components/Mui/CustomAutocomplete/CustomAutocomplete";
import { ICreateLeadUser, ILead, ILeadStageLocalization, ISortedLeads, IUser } from "./Interfaces";
import { leadStage } from './constants';
import { t } from 'i18next';

export const isProduction = () => {
    // return (process.env.NODE_ENV === 'production')
    return true;
}

export const getBaseURL = () => {
    if (isProduction()) {
        return "https://us-central1-explor-app.cloudfunctions.net/app"
    } else {
        return "https://us-central1-explor-dev.cloudfunctions.net/app"
    }
}

export const googleApiKey = () => {
    return process.env.GOOGLE_API_KEY ?? 'AIzaSyDscgvnthryp-Ll8A5PwpluyYJHafbovjQ';
}

// user authentication functions : end

export function isEmptyOrNullObj(obj: object | undefined | null) {
    if (obj === null || obj === undefined) {
        return true;
    }
    return Object.keys(obj).length === 0;
}

export function sortLeads(leads: ILead[]) {
    const sortedLeads = {} as any;
    leads.forEach((lead) => {
        if (!lead) return;

        if (!sortedLeads[lead.stage]) {
            sortedLeads[lead.stage] = [];
            // stages.push(lead.stage);
        }
        sortedLeads[lead.stage].push(lead);
    });

    const data: ISortedLeads = {
        sortedLeads: sortedLeads,
        // stages: stages
    }

    return data;
}

export const getDefaultValueInDropdown = (array: DropdownItemProps[], value?: string): DropdownItemProps | undefined => {
    if (!value) return undefined;
    const newArray = array.map(item => ({ ...item, label: t(item.label) }))
    return newArray.find(T => T.label === value);
}

export const getMultiSelectDefaultValues = (array: DropdownItemProps[], values?: string[]): DropdownItemProps[] | undefined => {
    if (!values) return undefined;
    const list = array.filter(item => values.includes(t(item.label)));
    return list;
}

export const getMultiSelectedItemsString = (array: DropdownItemProps[]): string[] => {
    return array.map(item => item.label);
}

export const formatDate = (day?: any, format: string = 'YYYY-MM-DD') => {
    if (!day) {
        return '-';
    }

    return dayjs(day).format(format);
}

export const formatString = (string?: string) => {
    if (!string)
        return '-';

    return string;
}

export const getMultiSelectedItemsView = (values?: string[]): string => {
    if (!values)
        return '-';

    return values.join(' / ');
}

export const generateUniqueKey = () => {
    return `${new Date().getTime()}`;
}

export const formatNumber = (value?: string) => {
    if (!value) return '0';
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const validatePhoneNumber = (phoneNumber?: string) => {
    if (!phoneNumber) return true;

    const internationalPhoneRegex = /^\+?[1-9]\d{1,14}$/;
    return internationalPhoneRegex.test(phoneNumber);
};

export const validateEmail = (email?: string) => {
    if (!email) return true;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

export const validateNumber = (value?: string) => {
    if (!value) return true;
    const numberRegex = /^[0-9]+$/;
    return numberRegex.test(value);
};

export const getTravelGroup = (lead: ILead) => {

    const adults = lead.travelGroupAdults ? `${lead.travelGroupAdults} Adult${parseInt(lead.travelGroupAdults) > 1 ? 's' : ''}` : '';
    const children = lead.travelGroupChildren ? `${lead.travelGroupChildren} Child${parseInt(lead.travelGroupChildren) > 1 ? 'ren' : ''}` : '';
    const infants = lead.travelGroupInfants ? `${lead.travelGroupInfants} Infant${parseInt(lead.travelGroupInfants) > 1 ? 's' : ''}` : '';

    const groups = [adults, children, infants].filter(group => group).join(', ');
    return groups;
}

export const getPortShortName = (port?: string) => {
    if (!port) return ('-')
    const splittedPort = port.split(' ');
    return `${splittedPort[splittedPort.length - 1]}`;
}

export const isEmptyString = (value: string | undefined | null) => {
    if (value) {
        return false;
    }

    return true
}

export const getLeadStage = (stage: string) => {
    const lead = leadStage.find(status => status.key === stage);

    if (lead) {
        return lead;
    } else {
        return { color: '#134B5F' } as ILeadStageLocalization;
    }
}

export const getStageColor = (stage: string) => {
    if (stage === 'PENDING') {
        return ('--c-gray');
    } else if (stage === 'IN-PROGRESS') {
        return ('--c-light-green')
    }
    return ('--c-dark-green')
}

export const getUsersFromList = (users: ICreateLeadUser[]) => {
    const list: DropdownItemProps[] = []

    users.forEach((user) => {
        const _user: DropdownItemProps = {
            label: user.name ?? '',
            id: user.id ?? ''
        }
        list.push(_user);
    })

    return list;
}

export function getMonthName(monthNumber: number): string {
    const months: string[] = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    if (monthNumber >= 1 && monthNumber <= 12) {
        return months[monthNumber - 1];
    } else {
        return "Invalid month number";
    }
}

function hashCode(str: string) {
    let hash = 0;
    if (str) {
        for (let i = 0; i < str.length; i++) {
            const char = str.charCodeAt(i);
            hash = (hash << 5) - hash + char;
            hash = hash & hash; // Convert to 32bit integer
        }
    }
    return hash;
}

function getColorComponent(value: number, min: number, max: number) {
    const range = max - min;
    return ((value % range) + range) % range + min;
}

function darkenColorComponent(value: number, factor: number) {
    return Math.max(0, Math.min(255, Math.floor(value * factor)));
}

export function getUniqueColor(id: string) {
    const hash = hashCode(id);

    const min = 100;
    const max = 255;

    const r = getColorComponent(hash, min, max);
    const g = getColorComponent(Math.floor(hash / 7), min, max);
    const b = getColorComponent(Math.floor(hash / 17), min, max);

    const backgroundColor = `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;

    const darkFactor = 0.6; // Adjust this factor to make the text color darker
    const darkR = darkenColorComponent(r, darkFactor);
    const darkG = darkenColorComponent(g, darkFactor);
    const darkB = darkenColorComponent(b, darkFactor);

    const textColor = `#${darkR.toString(16).padStart(2, '0')}${darkG.toString(16).padStart(2, '0')}${darkB.toString(16).padStart(2, '0')}`;

    return { backgroundColor, textColor };
}

export const stringAvatar = (name: string, fontSize?: string, size?: number) => {
    const color = getUniqueColor(name);
    const stringArray = name.split(' ');
    const first = stringArray[0][0];
    const second = stringArray.length >= 2 ? stringArray[1][0] : '';

    return {
        sx: {
            bgcolor: color.backgroundColor,
            color: color.textColor,
            fontSize: fontSize ?? '12px',
            width: size ?? 24, height: size ?? 24
        },
        children: first + second,
    };
}

export const capitalizeFirstLetter = (description: string): string => {
    return description.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
}

export const changeColorOpacity = (color?: string, opacity?: number) => {
    if (!color || !opacity) return 'white';
    if (opacity < 0) opacity = 0;
    if (opacity > 100) opacity = 100;

    const alpha = opacity / 100;

    if (color.startsWith('#')) {
        let r, g, b;

        if (color.length === 4) {
            r = parseInt(color[1] + color[1], 16);
            g = parseInt(color[2] + color[2], 16);
            b = parseInt(color[3] + color[3], 16);
        } else if (color.length === 7) {
            r = parseInt(color[1] + color[2], 16);
            g = parseInt(color[3] + color[4], 16);
            b = parseInt(color[5] + color[6], 16);
        } else {
            throw new Error("Invalid hex color");
        }

        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }
    else if (color.startsWith('rgb')) {
        return color.replace(/(rgb[a]?\(\d+,\s*\d+,\s*\d+)(,?\s*\d*\.?\d*\s*\))/, `$1, ${alpha})`);
    }
    else {
        return 'white';
    }
}

export const getOpacityFromColor = (color?: string): number => {
    if (!color) return 100;

    if (color.startsWith('rgba')) {
        const rgbaMatch = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+),\s*([\d\.]+)\)/);

        if (rgbaMatch) {
            const alpha = parseFloat(rgbaMatch[4]);
            return Math.round(alpha * 100);
        }
    }

    if (color.startsWith('rgb')) {
        return 100;
    }
    if (color.startsWith('#')) {
        return 100;
    }
    return 100;
}

export const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        }
    });

export const getAspectRatio = (value: string) => {
    const [height, width] = value.split('x').map(Number);
    return `${width} / ${height}`;
}


export const getWidthHeight = (resolution: string) => {
    const [_height, _width] = resolution.split('x').map(Number);
    let width = _width * 2.25;
    let height = _height * 2.25
    if (_width === 297) {
        width = _width * 3;
        height = _height * 3
    }
    return { width, height };
}

export const downloadPdf = async (url: string, filename: string, setLoading: CallableFunction) => {
    try {
        setLoading(true);
        const response = await fetch(url);

        if (!response.ok) {
            throw new Error("Failed to download the file.");
        }

        const blob = await response.blob();
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoading(false);

    } catch (error) {
        setLoading(false);
        console.error("Error downloading the PDF:", error);
    }
};

