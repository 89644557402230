import { CircularProgress } from "@mui/material";
import userImg from "../../assets/images/user.svg";
import { Link } from "react-router-dom";
import { useState } from "react";


type ForgotPasswordProps = {
    handleForgotPassword : (email:string,e:React.FormEvent<HTMLFormElement>) => void;
    loading : boolean
}

const ForgotPasswordForm = ({handleForgotPassword,loading} : ForgotPasswordProps) => {
    const [email,setEmail] = useState<string>("");

    const onSubmit = (e:React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        handleForgotPassword(email,e);
    }

    return(
        <form onSubmit={onSubmit} className="forgot-password-form">
            <div className="form-group">
                <input required type="text" className="form-control" placeholder="Email Address" onChange={(data) => setEmail(data.target.value)} value={email} />
            </div>
            <button type="submit" className="submit fz-16 primary-font fw-600 ttu c-white">
                {loading ? <CircularProgress color="inherit" size={28} /> : "Reset Password"}
            </button>
            <div className="sm-msg primary-font">
                <span className="inner">  <img src={userImg} alt="user" /> <span className="txt"> Remember your password? <span className="c-orange"> <Link to="/login" style={{ color: 'inherit', textDecoration: 'none' }} > Log in </Link>  </span> </span> </span>
            </div>
        </form>
    )
};

export default ForgotPasswordForm;