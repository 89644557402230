import { CircularProgress, Modal } from "@mui/material";
import "./CreateItineraryPage.scss";
import TextInput from "../TextInput/TextInput";
import { useEffect, useState } from "react";
import TextIcon from "../../../assets/images/builder/text_black.svg";
import OrientationIcon from "../../../assets/images/builder/orientation_black.svg";
import PageSizeIcon from "../../../assets/images/builder/pageSize_black.svg";
import { Close } from "@mui/icons-material";
import CustomMultiSelect from "../../Mui/CustomMultiSelect/CustomMultiSelect";
import { CustomSelect } from "../../Mui/CustomSelect/CustomSelect";
import { templateCategory } from "../../../helpers/constants";
import CustomTextField from "../../Mui/CustomTextField/CustomTextField";
import { IItinerary } from "../../../helpers/Interfaces";
import BuilderFunctions from "../../../pages/Dashboard/Builder/BuilderFunctions";

type CreateItineraryPageProps = {
  open: boolean;
  close: CallableFunction;
  itinerary: IItinerary,
  selectedPageId: string | number
};

const tags = [
  { label: "tag 1", id: "t1" },
  { label: "tag 2", id: "t2" },
  { label: "tag 3", id: "t3" },
  { label: "tag 4", id: "t4" },
];

const CreateItineraryPage = (props: CreateItineraryPageProps) => {
  const [formData, setFormData] = useState<{ title?: string, type?: string, tags?: string[] }>();
  const [loading, setLoading] = useState<boolean>(false);
  const builderFunctions = new BuilderFunctions()

  useEffect(() => {
    if (props.itinerary.name) {
      setFormData({ ...formData, title: props.itinerary.name })
    }
  }, [props.itinerary])

  const onSubmit = () => {
    setLoading(true);
    builderFunctions.createPage({
      itineraryId: props.itinerary.id ?? '',
      pageId: props.selectedPageId,
      title: formData?.title,
      type: formData?.type,
      tags: formData?.tags
    }).then(() => {
      setLoading(false);
      props.close();
    }).catch(() => {
      setLoading(false);
    })
  }

  return (
    <Modal open={props.open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <div className="new-itinerary-page-container">
        <span className="header-title">
          Create New Page
          <div className="close-outer">
            <button onClick={() => props.close()}>
              <Close />
            </button>
          </div>
        </span>

        <div className="create-page-outer">
          <div className="section-outer">
            <div className="section-header">
              <img src={TextIcon} alt="Text Icon" />
              <span>Template Name</span>
            </div>
            <CustomTextField size="small" value={formData?.title} onChange={(val: any) => setFormData({ ...formData, title: val.target.value })} placeholder="New Page for Hugo Mille #4" />
          </div>
          <div className="section-outer">
            <div className="section-header">
              <img src={OrientationIcon} alt="Text Icon" />
              <span>Template Category</span>
            </div>
            <CustomSelect label={""} options={templateCategory} onSelect={(val) => setFormData({ ...formData, type: val.label })} />
          </div>
          {/* <div className="section-outer">
            <div className="section-header">
              <img src={PageSizeIcon} alt="Text Icon" />
              <span>Tags (Optional)</span>
            </div>
            <CustomMultiSelect options={tags} label={''} onSelect={() => { }} />
          </div> */}
        </div>
        <button onClick={onSubmit} className="initializer-button">{loading ? <CircularProgress size={14} color="inherit" /> : "Create Page"}</button>
      </div>
    </Modal>
  );
};

export default CreateItineraryPage;
