import { getAspectRatio, getWidthHeight } from "./helperfunctions";
import { IBuilderView } from "./Interfaces";

export const pdfHtmlContent = (layout: IBuilderView, resolution: string) => {
    const scale = getWidthHeight(resolution)
    return (`
    <body>
    <style>
            .builder-container-outer {
                display: flex;
                gap: 28px;
            }
            .builder-container {
                width: 100%;
            }
            .builder-outer {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .builder-title {
                display: flex;
                align-items: center;
                font-weight: 500;
                gap: 10px;
            }
            .editor-area {
                min-width: 50px;
            }
            .editor-area:focus-visible {
                outline: none;
                border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
                margin-right: 10px;
            }
            .builder-outer-controls {
                display: flex;
                gap: 24px;
            }
            .icon-builder-btn {
                aspect-ratio: 1/1;
                border-radius: 10px;
                background-color: var(--c-light-orange);
                display: grid;
                place-items: center;
                border-radius: clamp(5rem, get-vw(8), 8rem);
                transition: all 0.35s;
            }
            .icon-builder-btn svg path, 
            .icon-builder-btn svg rect {
                transition: all 0.35s;
            }
            .icon-builder-btn:hover {
                background-color: var(--c-orange);
            }
            .icon-builder-btn:hover svg path {
                fill: var(--c-white);
            }
            .icon-builder-btn:hover svg rect {
                fill: var(--c-orange);
            }
            .builder-inner {
                display: flex;
                background-color: #fbfbfb;
                border-radius: 8px;
                margin-top: 25px;
                height: calc(100vh - 13rem);
                padding: 20px 40px;
            }
            .layout-select-outer {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                gap: 30px;
            }
            .monitor-image {
                width: 100px;
                height: 100px;
            }
            .header-description {
                width: 510px;
            }
            .pdf-editor-container {
                flex: 1;
                display: flex;
                flex-direction: column;
            }
            .editor-controls {
                display: flex;
                justify-content: space-between;
            }
            .editor-image-outer {
                position: relative;
                flex: 1;
                height: calc(-22rem + 100vh);
                position: relative;
            }
            .editor-image-outer-img {
                position: absolute;
                z-index: 1;
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
            }
            .editor-inner img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
                left: 0;
                top: 0;
                border-radius: 10px;
            }
            .editor-overlay-input {
                padding: 5px 10px;
                min-width: 100px;
                min-height: 100px;
                overflow-y: auto;
                max-height: calc(-27rem + 100vh);
                // border: 1px dotted #d9d9d9;
                background-color: transparent;
                border-radius: inherit;
            }
            .editor-overlay-input:focus-visible {
                outline: none;
            }
            .editor-input {
                padding: 5px 10px;
                height: 100%;
                // border: 1px dotted #d9d9d9;
                background-color: transparent;
                max-height: calc(100vh - 21.6rem);
                overflow-y: auto;
            }
            .editor-input:focus-visible {
                outline: none;
            }
            .latest-updated {
                display: flex;
                padding: 10px 0px;
                font-size: 16px;
                color: #b4b4b4;
            }
            .latest-updated img {
                margin-right: 8px;
            }
            .builder-content {
                flex: 1;
            }
            .builder-controls {
                min-width: 450px;
            }
            .upper-bts {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }
            .upper-bts-content {
                display: flex;
                gap: 18px;
                background-color: rgba(205, 205, 206, 0.2);
                border-radius: 14px;
                padding: clamp(0.625rem, 2.78125vw, 0.9375rem) clamp(0.9375rem, 0.0416666667vw, 1.25rem);
            }
            .editor-page-divider{
                margin-bottom: 20px;

            }
            
        </style>
      <div class="editor-page-divider">
                        <style>
                            .side-image{
                                // border-radius: ${layout?.view?.overlay ? '10px' : layout?.view?.split && !layout?.canvas?.split ? '10px 0px 0px 10px' : layout?.view?.split && layout?.canvas?.split ? '0px 10px 10px 0px' : ''};
                                opacity: ${layout?.image?.imageOpacity};
                            }
                            .editor-inner-container {
                                flex: 1;
                                display: flex;
                                background-color: white;
                                border-radius: 10px;
                                max-height: calc(-22rem + 100vh);
                                height: ${scale.height}px;
                                width: ${scale.width}px;
                            }
                            .editor-overlay-outer {
                                position: absolute;
                                padding-left: ${layout.editor?.leftPadding}px;
                                padding-right: ${layout.editor?.rightPadding}px;
                                padding-top: ${layout.editor?.topPadding}px;
                                padding-bottom: ${layout.editor?.bottomPadding}px;
                                background-color: ${layout?.editor?.backgroundColor ? layout?.editor?.backgroundColor : 'white'};
                                border-radius: ${layout?.editor?.radiusTLeft}px ${layout?.editor?.radiusTRight}px ${layout?.editor?.radiusBRight}px ${layout?.editor?.radiusBLeft}px;
                                // background-color: ${layout?.editor?.backgroundColor ?? 'white'};
                                top: ${layout?.editor?.positionTop}px;
                                left: ${layout?.editor?.positionLeft}px;
                            }
                            .pdf-editor-img{
                                    opacity: 1;
                                    position: static;
                                    height: 100%;
                            }
                            .pdf-editor-inner {
                                flex: 1;
                                overflow: hidden;
                                box-sizing: border-box;
                                position: relative;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                // max-height: calc(-22rem + 100vh);
                                ${!layout.view?.overlay
            ? (`
                                        padding-left: ${layout.editor?.leftPadding}px;
                                        padding-right: ${layout.editor?.rightPadding}px;
                                        padding-top: ${layout.editor?.topPadding}px;
                                        padding-bottom: ${layout.editor?.bottomPadding}px;
                                        background: ${layout?.editor?.overlayColor};
                                        // height: calc((100vh - 22.6rem + calc(45px - ${layout?.editor?.bottomPadding}px) + calc(45px - ${layout?.editor?.topPadding}px)));
                                    `)
            : (`
                                        // height: calc((100vh - 22.6rem + calc(45px - ${layout?.editor?.bottomPadding}px) + calc(45px - ${layout?.editor?.topPadding}px)));
                                        `)
        }
                            }
                            .editor-image-content {
                                position: absolute;
                                z-index: 2;
                                background-color: ${layout.image?.backgroundColor};
                                border-radius: ${layout?.view?.overlay ? '10px' : layout?.view?.split && !layout?.canvas?.split ? '10px 0px 0px 10px' : layout?.view?.split && layout?.canvas?.split ? '0px 10px 10px 0px' : ''};
                                padding-left: ${layout.image?.leftSplitPadding}px;
                                padding-right: ${layout.image?.rightSplitPadding}px;
                                padding-top: ${layout.image?.topSplitPadding}px;
                                padding-bottom: ${layout.image?.bottomSplitPadding}px;
                            }
                            .pdf-header-title {
                                font-size: ${layout?.image?.title?.fontSize ? layout?.image?.title?.fontSize : '32px'};
                                font-family: ${layout.image?.title?.fontFamily ? layout.image?.title?.fontFamily : 'var(--primary-font)'};
                                font-weight: ${layout.image?.title?.bold ? 'bold' : 'normal'};
                                text-decoration: ${layout.image?.title?.underLine ? 'underline' : ''} ${layout.image?.title?.strickThrough ? 'line-through' : ''};
                                font-style: ${layout.image?.title?.italic ? 'italic' : 'normal'};
                                color: ${layout.image?.title?.color ? layout.image?.title?.color : 'black'};
                                text-align: ${layout.image?.title?.align as any}
                            }
                            .pdf-sub-header-title {
                                font-size: ${layout?.image?.subTitle?.fontSize ? layout?.image?.subTitle?.fontSize : '32px'};
                                font-family: ${layout.image?.subTitle?.fontFamily ? layout.image?.subTitle?.fontFamily : 'var(--primary-font)'};
                                font-weight: ${layout.image?.subTitle?.bold ? 'bold' : 'normal'};
                                text-decoration: ${layout.image?.subTitle?.underLine ? 'underline' : ''} ${layout.image?.subTitle?.strickThrough ? 'line-through' : ''};
                                font-style: ${layout.image?.subTitle?.italic ? 'italic' : 'normal'};
                                color: ${layout.image?.subTitle?.color ? layout.image?.subTitle?.color : 'black'};
                                text-align: ${layout.image?.subTitle?.align as any}
                            }
                        </style>

                            <div style="background-color: ${layout?.view?.overlay ? layout?.editor?.overlayColor : 'white'}; flex-direction:${layout?.canvas?.split ? 'row-reverse' : 'row'};" class="editor-inner-container" >
                                <div style="display: ${(layout?.view?.blank || layout?.view?.overlay) ? 'none' : 'initial'};" class="editor-image-outer">
                                    ${layout?.image?.imageUrl
            ? `<img class="editor-image-outer-img side-image" src="${layout?.image?.imageUrl}" alt="" />`
            : ''}
                                    <div class="editor-image-content img">
                                        <div class="pdf-header-title">${layout?.image?.title?.text || ''}</div>
                                        <div class="pdf-sub-header-title">${layout?.image?.subTitle?.text || ''}</div>
                                    </div>
                                </div>
                                <div  class="pdf-editor-inner">
                                    ${(layout?.image?.imageUrl && !layout?.view?.split)
            ? `<img class="pdf-editor-img" style="border-radius: ${layout?.view?.overlay ? '10px' : layout?.view?.split && !layout?.canvas?.split ? '10px 0px 0px 10px' : layout?.view?.split && layout?.canvas?.split ? '0px 10px 10px 0px' : ''}; opacity: ${layout?.image?.imageOpacity}; position: ${layout?.view?.overlay ? 'static' : 'absolute'};" src="${layout?.image?.imageUrl}" alt="" />`
            : ''}
                                    ${!layout?.view?.overlay
            ? `<div class="editor-input">
                                                ${layout?.html}
                                            </div>`
            : `<div class="editor-overlay-outer">
                                                <div  class="editor-overlay-input">
                                                    ${layout?.html}
                                                </div>
                                            </div>`}
                                </div> 
                            </div>
                    </div>
        </body>             
`)
};
