import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import OTPInput from 'react-otp-input';
import ForgotPasswordFunctions from './forgotPasswordFunctions';
import './OtpVerification.scss';

type OtpVerificationFormProps = {
    email: string;
    onOtpSubmit: (otp: string) => void; 
    loading:boolean
};

const OtpVerificationForm = ({ email, onOtpSubmit,loading }: OtpVerificationFormProps) => {
    const [otp, setOtp] = useState<string>('');
    const [timer, setTimer] = useState<number>(32);
    const [resend, setResend] = useState<boolean>(false);
    const resendOtpFunction = new ForgotPasswordFunctions();

    useEffect(() => {
        let countdown: NodeJS.Timeout;
        if (timer > 0) {
            countdown = setInterval(() => {
                setTimer(prev => prev - 1);
            }, 1000);
        } else {
            setResend(true);
        }
        return () => clearInterval(countdown);
    }, [timer]);

    const handleResendOtp = () => {
        resendOtpFunction.sendOtp(
            { email },
            () =>{},
            (response: any) => {
                console.log("OTP resent", response);
                setTimer(32);
                setResend(false);
            },
            (error: any) => {
                console.error("Error resending otp", error);
            }
        );
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onOtpSubmit(otp); 
    };

    return (
        <form onSubmit={onSubmit} className='otp-verification-form'>
            <div className='form-group'>
                <OTPInput
                    value={otp}
                    onChange={(value: string) => setOtp(value)}
                    renderInput={(props) => <input {...props} />}
                    numInputs={8}
                    renderSeparator={<span>-</span>}
                />
            </div>
            <div className='resend-otp'>
                <p>
                    Didn't get a code?{' '}
                    {resend ? (
                        <span onClick={handleResendOtp}>
                            Click to resend
                        </span>
                    ) : (
                        <span>
                            Send code again in <strong>00:{timer < 10 ? `0${timer}` : timer}</strong>
                        </span>
                    )}
                </p>
            </div>
            <button type='submit' className="submit fz-16 primary-font fw-600 ttu c-white">
                {loading ? <CircularProgress color='inherit' size={28} /> : 'Verify'}
            </button>
        </form>
    );
};

export default OtpVerificationForm;
