const AssetsCategory = () => {
    return (
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.25006 4.3737H6.3334C6.66673 4.3737 6.87506 3.9987 6.66673 3.7487L5.12506 1.20703C4.9584 0.957031 4.5834 0.957031 4.41673 1.20703L2.87506 3.7487C2.7084 4.04036 2.91673 4.3737 3.25006 4.3737Z" fill="#E27C37" />
            <path d="M7.08594 8.95703C8.12147 8.95703 8.96094 8.11757 8.96094 7.08203C8.96094 6.0465 8.12147 5.20703 7.08594 5.20703C6.0504 5.20703 5.21094 6.0465 5.21094 7.08203C5.21094 8.11757 6.0504 8.95703 7.08594 8.95703Z" fill="#E27C37" />
            <path d="M3.95964 5.41406H1.45964C1.2513 5.41406 1.04297 5.6224 1.04297 5.83073V8.33073C1.04297 8.53906 1.2513 8.7474 1.45964 8.7474H3.95964C4.16797 8.7474 4.3763 8.53906 4.3763 8.33073V5.83073C4.3763 5.6224 4.16797 5.41406 3.95964 5.41406Z" fill="#E27C37" />
        </svg>
    )
}

export default AssetsCategory