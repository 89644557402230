import {
    Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Box, Tabs, Tab, Typography, FormControlLabel,
    Checkbox, Avatar, AvatarGroup,
    Skeleton,
    CircularProgress,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState, useCallback } from "react";
import SecondaryButton from "../SecondaryButton/SecondaryButton";
import PluseSvg from "../../assets/images/buttons/PluseSvg";
import fileUpload from "../../assets/images/upload.svg";
import CalenderSvg from "../../assets/images/ViewLead/CalenderSvg";
import { IAssignee, IItineraryView, ILead, ILeadTask } from "../../helpers/Interfaces";
import { formatDate, formatNumber, formatString, getMultiSelectedItemsView, getTravelGroup, stringAvatar } from "../../helpers/helperfunctions";
import CustomTextField from "../Mui/CustomTextField/CustomTextField";
import ViewLeadFunctions from "./ViewLeadFunctions";
import CustomTaskStageDropDown from "../Mui/CustomTaskStageDropDown/CustomTaskStageDropDown";
import { LeadTaskStages } from "../../helpers/types";
import { Delete, Edit, HorizontalRule } from "@mui/icons-material";
import CustomDatePicker from "../Mui/CustomDatePicker/CustomDatePicker";
import { Dayjs } from "dayjs";
import UserSvg from "../../assets/images/ViewLead/UserSvg";
import AddUserSvg from "../../assets/images/ViewLead/AddUserSvg";
import { DropdownItemProps } from "../Mui/CustomAutocomplete/CustomAutocomplete";
import SearchableDropdown from "../SerchableDropdown/SearchableDropdown";
import EditIcon from '../../assets/images/ViewLead/editRoundedSvg.svg'
import AddIcon from '../../assets/images/ViewLead/addRoundedSvg.svg'
import { t } from "i18next";
import ItineraryCard from "../ItineraryCard/ItineraryCard";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { useNavigate } from "react-router-dom";

const CustomTabPanel = ({ children, value, index, ...other }: { children?: React.ReactNode, index: number, value: number }) => {
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const TaskCard = ({ task, changeStage, remove, onDateChange, users, onAssign }:
    { task: ILeadTask, changeStage: CallableFunction, remove: CallableFunction, onDateChange: CallableFunction, users: DropdownItemProps[], onAssign: CallableFunction }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const assignees = task.assignees ?? [];

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const assignee: DropdownItemProps[] = task.assignees
        ? task.assignees.map(t => ({ id: t.id, label: t.name }))
        : [];

    return (
        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }} className="task-row">
            <TableCell classes={{ root: 'MuiTableCell-root-container' }}>
                <span className="user-header-icon c-pointer" onClick={handleClick} >
                    {assignees.length > 0
                        ? (<AvatarGroup max={1}>
                            {assignees.map(avatar => (<Avatar className="avatar-icon" {...stringAvatar(avatar.name, '10px', 21)} />))}
                        </AvatarGroup>)
                        : (<AddUserSvg />)}
                </span>
            </TableCell>
            <TableCell >
                <span className="task-name-details">{task.description}</span>
            </TableCell>
            <TableCell align="right">
                <CustomTaskStageDropDown defaultStage={task.status} changeStage={changeStage} />
                {/* <span className={`label small-label ${stageColor}`}>{stage}</span> */}
            </TableCell>
            {/* <TableCell>
                <span className="icon-txt urgent">
                    <UrgentSvg />
                    <span>Urgent</span>
                </span>
            </TableCell> */}
            <TableCell align="right">
                <CustomDatePicker date={task.dueDate?.toString()} setDate={(date: Dayjs) => onDateChange(date)} >
                    <span className="icon-txt date">
                        <CalenderSvg />
                        <span>{formatDate(task.dueDate, 'MMM. D, YYYY')}</span>
                    </span>
                </CustomDatePicker>
            </TableCell>
            <TableCell align="right">
                <button onClick={() => remove()}>
                    <Delete className="c-pointer task-delete" />
                </button>
            </TableCell>
            <SearchableDropdown selectedItems={assignee} onSelect={(val) => {
                handleClose();
                onAssign(val);
            }} initialDetails={users} id={id} open={open} anchorEl={anchorEl} handleClose={handleClose} />
        </TableRow>

    );
}

const NewTaskCard = ({ addTask, showNewTask }: { addTask: CallableFunction, showNewTask: boolean }) => {
    const [task, setTask] = useState<string>('');
    const [taskError, setTaskError] = useState<boolean>(false);
    const [checked, setChecked] = useState<boolean>(false);

    useEffect(() => {
        setChecked(false);
        setTaskError(false);
        setTask('');
    }, [showNewTask])

    return (showNewTask
        ? (<TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            {/* <TableCell>
                <span></span>
            </TableCell> */}
            <TableCell component="th" scope="row" colSpan={3}>
                <CustomTextField error={taskError} defaultValue={task} label="Task name" size="small" helperText="This field is required"
                    type='text'
                    onChange={(val) => {
                        setTask(val.target.value)
                    }} />
            </TableCell>
            {/* <TableCell>
                <span></span>
            </TableCell>*/}
            <TableCell>
                <span></span>
            </TableCell>
            <TableCell align="right">
                {/* <Chip label="ADD" size="small" /> */}
                <FormControlLabel sx={{ paddingBottom: taskError ? '20px' : undefined }} control={<Checkbox size='small' checked={checked} onChange={(val) => {
                    if (task === '') {
                        setTaskError(true);
                        setChecked(false);
                        return;
                    } else {
                        setTaskError(false);
                        setChecked(true);
                    }
                    if (val.target.checked) {
                        addTask(task);
                    }
                }} />} label={undefined} />
            </TableCell>
        </TableRow>)
        : (<></>));

}

const OverViewCard = ({ header, description, fullWidth }: { header: string, description: string, fullWidth?: boolean }) => {
    return (
        <div className={`accordian-info-item ${fullWidth ? 'fw-100' : ''}`}>
            <span className="s-label db">{header}</span>
            <span className="info-txt db">{description}</span>
        </div>
    );
}

type CurrentItineraryProps = {
    divARef: React.MutableRefObject<HTMLDivElement | null>,
    lead: ILead,
    setLeads: CallableFunction,
    refresh: CallableFunction,
    onEditLead: CallableFunction,
    users: DropdownItemProps[],
    manageAssignee: ({ }: IAssignee, id: string) => void,
    itineraries: IItineraryView,
    itineraryLoading: boolean
}

const CurrentItinerary = ({ divARef, lead, setLeads, refresh, onEditLead, users, manageAssignee, itineraries, itineraryLoading }: CurrentItineraryProps) => {
    const [value, setValue] = useState(0);
    const [newTask, setNewTask] = useState(false);
    const functions = new ViewLeadFunctions();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);


    const toggleNothing = () => {
        setNewTask(!newTask);
    };

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <div ref={divARef} className="info-sec bdr-10">
            <div className="tab-outer-sec">
                <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label={t('tasks')} className="primary-font fz-16 ttu" {...a11yProps(0)} />
                            <Tab label={t('overview')} {...a11yProps(1)} style={{ marginRight: '14px' }} />
                            <Tab label={t('itineraries')} {...a11yProps(2)} />
                            {value === 1
                                ? (<div className="edit-lead" >
                                    <img src={EditIcon} onClick={() => onEditLead()} />
                                </div>)
                                : (<></>)}
                            {value === 2
                                ? (<div className="edit-lead" >
                                    {loading
                                        ? (<CircularProgress color="inherit" size={20} />)
                                        : (<img src={AddIcon} onClick={() => functions.handleGenerateItinerary(lead, setLoading, navigate, dispatch)} />)}
                                </div>)
                                : (<></>)}
                        </Tabs>
                    </Box>

                    <CustomTabPanel value={value} index={0}>
                        <TableContainer component={Paper} className="mt-20">
                            <Table sx={{ minWidth: 350, border: 0 }} aria-label="simple table" className="default-tabel-box">
                                <TableHead>
                                    <TableRow>
                                        <TableCell >
                                            <span className="user-header-icon">
                                                <UserSvg />
                                            </span>
                                        </TableCell>
                                        <TableCell >{t('taskName')}</TableCell>
                                        <TableCell align="right">{t('taskStatus')}</TableCell>
                                        {/* <TableCell align="right">Priority</TableCell> */}
                                        <TableCell align="right">{t('dueBy')}</TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {lead.task?.map((task) => (
                                        <TaskCard users={users} key={task.id} task={task}
                                            onAssign={(val: DropdownItemProps) => manageAssignee({
                                                name: val.label, id: val.id.toString()
                                            }, task.id)}
                                            changeStage={(value: LeadTaskStages) => functions.updateTaskStage(task.id, value, lead, setLeads, refresh)}
                                            remove={() => functions.deleteLeadTask(lead.id ?? '', task.id, refresh, lead, setLeads)}
                                            onDateChange={(date: Dayjs) => functions.updateTaskStageDate(task.id, date, lead, setLeads, refresh)} />))}
                                    <NewTaskCard showNewTask={newTask} addTask={(value: string) => functions.addTask(value, lead, setLeads, () => toggleNothing())} />
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className="small-btn">
                            <SecondaryButton label={newTask ? t('remove') : t('addNew')} icon={newTask ? (<HorizontalRule />) : (<PluseSvg />)} onClick={toggleNothing} />
                        </div>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4-content" id="panel4-header">
                                <Typography>{t('attachments')}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="accordian-inner accordian-4-col-inner">
                                    <div className="accordian-info-item fw-100">
                                        <img src={fileUpload} alt="" className="file-up-img" />
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion defaultExpanded className="ass">
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                                <Typography>{t('overview')}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="accordian-inner accordian-4-col-inner">
                                    <OverViewCard header={t('startDate')} description={formatDate(lead.startDate, 'MMM. D, YYYY')} />
                                    <OverViewCard header={t('endDate')} description={formatDate(lead.endDate, 'MMM. D, YYYY')} />
                                    {lead.daysAndNights
                                        ? (<OverViewCard header={t('daysNights')} description={formatString(lead.daysAndNights)} />)
                                        : (<></>)}
                                    <OverViewCard header={t('budgetRange')} description={`£${formatNumber(lead.budgetRangeFrom)} - £${formatNumber(lead.budgetRangeTo)}`} />
                                    <OverViewCard header={t('travelGroup')} description={getTravelGroup(lead)} />
                                    <OverViewCard header={t('portOfArrival')} description={formatString(lead.portArrival)} />
                                    <OverViewCard header={t('modeOfArrival')} description={formatString(lead.modeOfArrival)} />
                                    <OverViewCard header={t('visaStatusArrival')} description="Self-Obtained" />
                                    <OverViewCard header={t('transportStatus')} description="Required" />
                                    <OverViewCard header={t('portOfDeparture')} description={lead.departFromSame
                                        ? formatString(lead.portArrival)
                                        : formatString(lead.portDeparture)} />
                                    <OverViewCard header={t('modeOfDeparture')} description={lead.departFromSame
                                        ? formatString(lead.modeOfArrival)
                                        : formatString(lead.modeOfDeparture)} />
                                    <OverViewCard header={t('visaStatusDeparture')} description="Not Applicable" />
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
                                <Typography>{t('considerations')}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="accordian-inner accordian-4-col-inner">
                                    <OverViewCard fullWidth header={t('specialCargo')} description={getMultiSelectedItemsView(lead.spacialCargoEquipment)} />
                                    <OverViewCard fullWidth header={t('accessibilityRequirements')} description={getMultiSelectedItemsView(lead.accessibility)} />
                                    <OverViewCard fullWidth header={t('dietaryRestrictions')} description={getMultiSelectedItemsView(lead.dietaryRestrictions)} />
                                    <OverViewCard fullWidth header={t('specialNotes')} description={formatString(lead.notes)} />

                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-content" id="panel3-header">
                                <Typography>{t('preferences')}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="accordian-inner accordian-4-col-inner">
                                    <OverViewCard fullWidth header={t('typeOfTrip')} description={getMultiSelectedItemsView(lead.tripTypes)} />
                                    <OverViewCard header={t('travelStyle')} description={formatString(lead.travelStyle)} />
                                    <OverViewCard header={t('levelOfActivity')} description={formatString(lead.levelOfActivity)} />
                                    <OverViewCard header={t('preferredPace')} description={formatString(lead.preferredSpace)} />
                                    <OverViewCard header={t('preferredLanguages')} description={getMultiSelectedItemsView(lead.languages)} />
                                    <OverViewCard fullWidth header={t('specificLocations')} description={getMultiSelectedItemsView(lead.specialLocations)} />
                                    <OverViewCard fullWidth header={t('landscapePreferences')} description={getMultiSelectedItemsView(lead.specialLandscapes)} />
                                    <OverViewCard fullWidth header={t('climatePreferences')} description={getMultiSelectedItemsView(lead.climatePreferences)} />
                                    <OverViewCard fullWidth header={t('culturalInterests')} description={getMultiSelectedItemsView(lead.cultureStructure)} />
                                    <OverViewCard fullWidth header={t('currentItinerary')} description={getMultiSelectedItemsView(lead.specificActivities)} />
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        <div className="itinerary-data">
                            <div className="submitted-itineraries">
                                {itineraryLoading
                                    ? (<>
                                        <Skeleton width={'392px'} height={'102px'} variant='rounded' />
                                    </>)
                                    : ((itineraries.sent.length > 0)
                                        ? (itineraries.sent?.map(item => <ItineraryCard itinerary={item} onClick={() => { }} onEdit={() => functions.handleEditItinerary(item.id ?? '', dispatch, navigate)} />))
                                        : (<div className="primary-font">No Sent Itineraries found</div>))}

                            </div>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-content" id="panel3-header">
                                    <Typography>{t('draft')}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="accordian-inner accordian-4-col-inner submitted-itineraries">
                                        {itineraryLoading
                                            ? (<>
                                                <Skeleton width={'392px'} height={'102px'} variant='rounded' />
                                            </>)
                                            : (itineraries.draft.length > 0
                                                ? (itineraries.draft?.map(item => <ItineraryCard key={item.id} itinerary={item} onClick={() => { }} onEdit={() => functions.handleEditItinerary(item.id ?? '', dispatch, navigate)} />))
                                                : (<div className="primary-font">No Draft Itineraries found</div>))}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </CustomTabPanel>
                </Box>
            </div>

        </div>
    )
}

export default CurrentItinerary