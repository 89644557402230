import { Modal } from "@mui/material";
import "./InsertImage.scss";
import { CloseSharp } from "@mui/icons-material";
import { MouseEventHandler, useState } from "react";
import MediaInput from "../MediaInput/MediaInput";
import { generateUniqueKey } from "../../../helpers/helperfunctions";
import Image from "../../../assets/images/builder/Image";
import AddImage from "../../../assets/images/builder/AddImage";
import SecondaryButton from "../../SecondaryButton/SecondaryButton";

type InsertImageProps = {
  open: boolean;
  close: MouseEventHandler<HTMLButtonElement> | CallableFunction | any;
  addImage: CallableFunction;
};

const InsertImage = (props: InsertImageProps) => {
  const [imageUrl, setImageUrl] = useState<string | undefined>();

  const handleAddImage = () => {
    props.addImage({ altText: '', src: imageUrl });
    setImageUrl(undefined);
    props.close();
  }

  return (
    <Modal open={props.open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <div className="insert-image-container">
        <div className="header-outer">
          <span className="header-title">Insert Image</span>
          <button onClick={() => {
            setImageUrl(undefined);
            props.close();
          }}><CloseSharp /></button>
        </div>
        <div className="control-column">
          <MediaInput
            imageId={`lexical-images-${generateUniqueKey()}`}
            sx={{ width: '100%' }}
            onUpload={(val: any) => {
              if (val) {
                setImageUrl(val);
              }
            }}
            icon={<Image />} />
          <div className="centered-container">
            {(imageUrl && imageUrl !== undefined)
              ? (<img src={imageUrl} />)
              : (<AddImage />)}
          </div>
        </div>
        <div className="footer-outer">
          <SecondaryButton onClick={handleAddImage} label='Insert Image' />
        </div>
      </div>
    </Modal>
  );
};

export default InsertImage;
